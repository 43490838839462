import {
  FETCHED_USER_PROFILE,
  FETCHED_USERS_LIST,
  ADD_USER_PROFILE,
  ADD_EMAIL_USER_PROFILE,
  ADD_EMAIL_VERIFIED_USER_PROFILE,
  LOGOUT_USER_PROFILE,
  CHANGE_ACCESS_USER_PROFILE
} from './types';
import { request } from 'graphql-request';
import { getAspirants } from '../admin';
import { URL, URL_API_GRAPHQL_V2 } from '../../../variables/enviroments-files/env';

const axios = require('axios');

export function getProfile () {

  const query = `{
	  usuario(id: 1) {
	    id,
	    cedula
	  }
	}`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {
      dispatch({
        type: FETCHED_USER_PROFILE,
        payload: data
      });
    })
  }
}

export function getUsers () {

  const query = `{
	  usuarios {
      cedula
      nombres
      rol
      activo
	  }
	}`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {
      dispatch({
        type: FETCHED_USERS_LIST,
        payload: data.usuarios
      });
    })
  }
}

export function saveProfile (profile) {
  return {
    type: ADD_USER_PROFILE,
    payload: profile
  }
}

export function saveEmail (email) {
  return {
    type: ADD_EMAIL_USER_PROFILE,
    payload: email
  }
}

export function changeAccess(cedula, nro_pagina) {

  const query = `mutation {
    changeAccess(cedula: "${cedula}")
  }`;

  return dispatch => {
    request(URL_API_GRAPHQL_V2, query).then(data => {
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Acceso al Aula Virtual actualizado', type: 'success' } });

      // Consultar de nuevo los datos de los usuarios
      dispatch(getAspirants(nro_pagina));
    })
  }
}

export function saveVerificationEmail () {
  return {
    type: ADD_EMAIL_VERIFIED_USER_PROFILE
  }
}

export function logout () {
  return dispatch => {
    axios({
      method: 'get',
      url: URL + '/logout',
      withCredentials: true
    }).then(response => {
      dispatch({ type: LOGOUT_USER_PROFILE, payload: {} });
    })
  }
}