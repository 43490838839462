import { FETCHED_TRANSFERS_LIST }                         from '../transfer/types';
import { FETCHED_BANKS_LIST }                             from '../banks/types';
import {FETCHED_ASPIRANTS_LIST, FETCHED_EXCHANGE_RATE, FETCHED_METRICS_GLOBAL} from './types'
import { request } from 'graphql-request';

import { URL, URL_API_GRAPHQL_V2 } from '../../../variables/enviroments-files/env';
import {FETCHED_USERS_LIST} from "../user/types";
import {FETCHED_REGISTRATION_COSTS} from "../parent/types";

const moment = require('moment');

export function getDataAdmin () {

  const query = `{
    metricas {
      nro_inscritos
      nro_aspirantes
      monto_ingresos
      nro_morosos
      grafica_inscritos
      grafica_ingresos
      grafica_solvencias
    }
    aspirantes {
      rows {
        id
        cedula
        apellidos
        nombres
        gradoletra
        cedulaRepresentante
        nombresRepresentante
        estadoDeInscripcion
        deudaAnterior
        bloqueado
        autorizacion {
          id
          aspirante_id
          administrador_id
          deuda_cancelada
          creado_en
        }
        planDeCobro {
          conceptos {
            nroCuota
            descripcion
            fechaVencimiento
            monto
            montoEnDivisa
          }
        }
      }
      nro_paginas
      pagina_actual
    }
    transferencias (fecha_inicio: "${moment().format("YYYY-MM-DD")}", fecha_fin: "${moment().format("YYYY-MM-DD")}") {
      rows {
        id
        cedula
        nombres
        banco_id
        referencia
        monto
        fecha_emision
        estado_transferencias_id
      }
      nro_paginas
      pagina_actual
    }
    bancos {
      id
      nombre_corto
    }
    tasaCambio
    costoInscripcion {
      costo_divisas
      moneda_divisa
      costo_local
      tasa_divisa_id
    }
    usuarios {
      id
      cedula
      nombres
      rol
      activo
      locked
	  }
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_METRICS_GLOBAL,
        payload: data.metricas
      });

      dispatch({
        type: FETCHED_ASPIRANTS_LIST,
        payload: data.aspirantes
      });

      dispatch({
        type: FETCHED_TRANSFERS_LIST,
        payload: data.transferencias
      });

      dispatch({
        type: FETCHED_BANKS_LIST,
        payload: data.bancos
      });

      dispatch({
        type: FETCHED_EXCHANGE_RATE,
        payload: data.tasaCambio
      });

      dispatch({
        type: FETCHED_REGISTRATION_COSTS,
        payload: data.costoInscripcion
      });

      dispatch({
        type: FETCHED_USERS_LIST,
        payload: data.usuarios
      });

    })
  }
}

export function getAspirants (numberPage = 1, cedula = "", apellidos = "") {

  const query = `{
    aspirantes (nro_pagina: ${numberPage}, cedula: "${cedula}", apellidos: "${apellidos}") {
      rows {
        id
        cedula
        apellidos
        nombres
        gradoletra
        cedulaRepresentante
        nombresRepresentante
        estadoDeInscripcion
        deudaAnterior
        bloqueado
        autorizacion {
          id
          aspirante_id
          administrador_id
          deuda_cancelada
          creado_en
        }
        planDeCobro {
          conceptos {
            nroCuota
            descripcion
            fechaVencimiento
            monto
            montoEnDivisa
          }
        }
      }
      nro_paginas
      pagina_actual
    }
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_ASPIRANTS_LIST,
        payload: data.aspirantes
      });

      dispatch({ type: 'HIDE_SNACKBAR' });

    })
  }
}

export function getExchangeRate () {

  const query = `{
    tasaCambio
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_EXCHANGE_RATE,
        payload: data.tasaCambio
      });

    })
  }
}

export function saveNewExchangeRate(ExchangeRateInput) {

  const query = `mutation {
    registrarTasaCambio (
      nuevaTasa: {
        divisa_id: 1,
        monto: ${ExchangeRateInput.monto},
        definido_por: ${ExchangeRateInput.userId}
      }
    )
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      // Consultar nueva tasa de cambio
      dispatch(getExchangeRate());

      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Tasa de cambio registrada con éxito', type: 'success' } });
    })
  }
}

export function changeStatusAspirant(id, status, nro_pagina_actual) {

  const query = `mutation {
    cambiarEstadoAspirante (
      id: ${id},
      estado: "${status}"
    )
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      // Consultar nuevos listado de aspirantes
      dispatch(getAspirants(nro_pagina_actual));

      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Permiso de inscripción actualizado', type: 'success' } });
    })
  }
}

export function allowRegisterWithDebt (aspirante_id, administrador_id, nro_pagina_actual) {

  const query = `mutation {
    autorizarInscripcion(autorizacion: {
      aspirante_id: ${aspirante_id},
      administrador_id: ${administrador_id}
    })
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      // Consultar nuevos listado de aspirantes
      dispatch(getAspirants(nro_pagina_actual));

      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Autorizado a inscribir con deuda', type: 'success' } });
    })
  }
}


export function editDebtAspirant(EditDebtInput) {

  const query = `mutation {
    editDebt (id: ${EditDebtInput.aspirantId}, debt: ${EditDebtInput.mount})
  }`;

  return dispatch => {
    request(URL_API_GRAPHQL_V2, query).then(data => {

      // Consultar nuevos listado de aspirantes
      dispatch(getAspirants(EditDebtInput.nro_pag_now));

      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Deuda pendiente registrada con éxito', type: 'success' } });
    })
  }
}
