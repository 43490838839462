import {
  FETCHED_ASPIRANTS_LIST,
  FETCHED_EXCHANGE_RATE,
  FETCHED_METRICS_GLOBAL
} from '../../actions/admin/types';

const initialState = {
  aspirants: {
    rows:    [],
    nro_paginas:   0,
    pagina_actual: 1
  },
  exchangeRate: 0,
  metrics: {
    nro_inscritos:  0,
    nro_aspirantes: 0,
    monto_ingresos: 0,
    nro_morosos:    0,
    grafica_inscritos: [0, 0, 0, 0, 0, 0, 0],
    grafica_ingresos: [0, 0, 0, 0, 0, 0, 0],
    grafica_solvencias: [0, 0, 0, 0, 0, 0, 0]
  }
};

export default function (state = initialState, action) {

  let result;

  switch (action.type) {
    case FETCHED_ASPIRANTS_LIST:
      result = {
        ...state,
        aspirants: action.payload
      };
      break;
    case FETCHED_EXCHANGE_RATE:
      result = {
        ...state,
        exchangeRate: action.payload
      };
      break;
    case FETCHED_METRICS_GLOBAL:
      result = {
        ...state,
        metrics: action.payload
      };
      break;
    default:
      result = state;
      break;
  }

  return result;
}