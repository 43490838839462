import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  FETCH_PERIOD
} from '../../actions/utils/types';

const initialState = {
  snackbar: {
    active: false,
    message: "Cargando...",
    position: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    type: 'info'
  },
  period: {
    nro: '',
    first_year: '',
    second_year: '',
    short_text: ''
  }
};

export default function (state = initialState, action) {

  let result;

  switch (action.type) {
    case SHOW_SNACKBAR:
      result = {
        ...state,
        snackbar: {
          active:   true,
          message:  action.payload.message,
          position: ("position" in action.payload) ? action.payload.position : { vertical: 'bottom', horizontal: 'left' },
          type:     ("type" in action.payload) ? action.payload.type : 'info'
        }
      };
      break;
    case HIDE_SNACKBAR:
      result = {
        ...state,
        snackbar: {
          active: false,
          message: "",
          position: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          type: 'info'
        }
      };
      break;
    case FETCH_PERIOD:
      result = {
        ...state,
        period: action.payload
      };
      break;
    default:
      result = state;
      break;
  }

  return result;
}