import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

// Elements UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Table from "../../../components/Table/Table";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { NumberFormatSimple } from "../../Utils/NumberFormat";
import { getNamesWithFormat } from "../../../utils"
import RegisterPay from "./RegisterPay";

const moment = require("moment");

export default function SelectConcepts(props) {

  const dispatch = useDispatch();

  const {
    student = {
      planDeCobro: {
        conceptos: [1]
      }
    },
    open,
    onClose,
    user
  } = props;

  // Status select all concepts option
  const [selectedAll, setSelectedAll] = useState(false);

  // List of index of concept to pay
  const [conceptsToPay, setConceptsToPay] = useState([]);

  // List of index of concepts existing
  const conceptsExisting = student.planDeCobro.conceptos.map((concept, index) => index);

  useEffect(() => checkSelectAll(), [conceptsToPay]);

  const [openRegisterPay, setOpenRegisterPay] = useState(false);

  /**
   * Get list concepts to payments
   *
   * @return {array}
   */
  function getConcepts() {

    // Consult concepts of payment
    let concepts = formatDataConceptsForTable(student.planDeCobro.conceptos);

    // Format data
    concepts = concepts.map((concept, index) => [
      concept.nroCuota,
      concept.descripcion,
      moment(concept.fechaVencimiento).format("DD-MM-YYYY"),
      <NumberFormatSimple number={concept.monto} />,
      (isFirstConceptOfQuota(concept.nroCuota)) ? <><input type="checkbox" checked={isSelected(index)} onChange={(event) => changeListToPay(event.target.checked, index)} /></> : ""
    ]);

    // Add totally selected to pay
    const totallyMount = getTotallyMount();
    concepts.push([<><strong> Total a pagar:</strong></>, '', '', <NumberFormatSimple number={totallyMount} />]);

    return concepts;
  }

  /**
   * Allow fields nroQuota and expiration day
   * only in first concept of quota
   *
   * @param {array} concepts
   * @return {array}
   */
  function formatDataConceptsForTable(concepts) {

    let quotaNow = 0;

    concepts.forEach(concept => {
      if (concept.nroCuota !== quotaNow)
        quotaNow = concept.nroCuota
      else if (concept.nroCuota === quotaNow) {
        concept.nroCuota = "";
        concept.fechaVencimiento = "";
      }
    });

    return concepts;
  }

  /**
   * Check if is first concept of the quota
   *
   * @param {number|string} nroCuota
   * @return {boolean}
   */
  function isFirstConceptOfQuota(nroCuota) {
    return Number.isInteger(nroCuota);
  }

  /**
   * Check if is selected of concept
   *
   * @param {int} index
   * @return {boolean}
   */
  function isSelected(index) {
    return conceptsToPay.indexOf(index) !== -1;
  }

  /**
   * Get totally mount of concepts selected
   *
   * @return {number}
   */
  function getTotallyMount() {

    let totallyMount = 0

    conceptsToPay.forEach(conceptIndex => {
      student.planDeCobro.conceptos.forEach((concept, index) => {
        if (conceptIndex === index)
          totallyMount += concept.monto
      });
    });

    return totallyMount
  }

  /**
   * Change list of concepts to pay
   *
   * @param {bool} value
   * @param {int}  index
   */
  function changeListToPay(value, index) {

    // Add concept(s) to pay
    if (value) {
      index = getIndexOfLastConceptForQuota(index)
      setConceptsToPay(conceptsExisting.filter(conceptIndex => conceptIndex <= index));
    // Remove concept(s) to pay
    } else {
      setConceptsToPay(conceptsToPay => conceptsToPay.filter(conceptIndex => conceptIndex < index));
    }
  }

  /**
   * Get index of last subconcept of the quota
   *
   * @param {int} index - Index of the quota
   * @return {int} - Index of last subconcept
   */
  function getIndexOfLastConceptForQuota(index) {

    const concepts = student.planDeCobro.conceptos;

    let nroQuota = concepts[index].nroCuota;
    let lastIndexConcept = index;

    for (let i=index; i<concepts.length; i++) {
      if (concepts[i].nroCuota !== nroQuota && concepts[i].nroCuota !== "")
        break;
      lastIndexConcept = i;
    }

    return lastIndexConcept;
  }

  /**
   * Is selected all concepts
   *
   * @return {void}
   */
  function checkSelectAll() {
    setSelectedAll(conceptsToPay.length === conceptsExisting.length)
  }

  /**
   * Select or deselect all concepts to pay
   *
   * @param {boolean} value
   */
  function selectOrDeselectAll(value) {
    // Select all concepts
    if (value)
      setConceptsToPay(conceptsExisting);
    // Deselect all concepts
    else
      setConceptsToPay([]);

    setSelectedAll(value);
  }

  /**
   * Continue to pay concepts selected
   *
   */
  function continueToPay() {

    // If not is selected concepts
    if (!isSelectedConcepts()) {
      // Show message of validation
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Seleccione los conceptos a cancelar', type: 'warning' } });
      return;
    }
    setOpenRegisterPay(true);
  }

  /**
   * Check if is selected concepts
   */
  function isSelectedConcepts() {
    return conceptsToPay.length > 0;
  }

  /**
   * Close popup register transfers in it
   *
   */
  function closePayConcepts() {
    // Clean component for close
    clearStateComponent();

    // Close components
    setOpenRegisterPay(false);
    onClose();
  }

  function clearStateComponent() {

    setSelectedAll(false);
    setConceptsToPay([]);
  }

  function getConceptsSeleted() {
    return student.planDeCobro.conceptos.slice(0, conceptsToPay.length);
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Seleccionar cuotas a pagar para {getNamesWithFormat(student.nombres)} {getNamesWithFormat(student.apellidos)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A continuación, debera seleccionar los conceptos que desea cancelar para procesar el pago.
          </DialogContentText>
          <Table
            tableHeaderColor="info"
            tableHead={[
              "Nro. cuota",
              <><span style={{marginRight: '15em'}}>Concepto</span></>,
              "Fecha de vencimiento",
              "Monto (Bs.)",
              <><input type="checkbox" checked={selectedAll} onChange={(event) => selectOrDeselectAll(event.target.checked)} /></>
            ]}
            tableData={getConcepts()}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => closePayConcepts()}>
            Regresar
          </Button>
          <Button color="primary" onClick={() => continueToPay()}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Form register payment */}
      <RegisterPay
        open={openRegisterPay}
        user={user}
        students={[student]}
        isDebt={false}
        mountToPay={getTotallyMount()}
        conceptsToPay={getConceptsSeleted()}
        onClose={() => closePayConcepts()}
      />

    </React.Fragment>
  );
}
