import React, { useState, useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { URL, nameShortCollege } from "../../variables/enviroments-files/env";

import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useLocation } from "react-router-dom";

import { saveVerificationEmail } from '../../redux/actions/user';

import logo from "assets/img/pin-code.png";

const axios = require('axios');

const moment = require('moment');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sisconot.com.ve/">
        Sisconot
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: theme.spacing(1),
    width: "69px",
    top: "52px",
    verticalAlign: "middle",
    border: "0"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function VerificationCode() {

  let location = useLocation();
  let { allowChangeEmail } = location.state || { allowChangeEmail: { allow: true } };

  // Constants values for control time out
  const counterMinutes = 5;
  const diffInSeconds  = counterMinutes * 60;

  // Dispatch event
  const dispatch = useDispatch();

  // Styles view
  const classes = useStyles();

  // History routes
  const history = useHistory();

  // Fields values
  const [code, setCode] = useState("");

  // Form view validations
  const [formInvalid, setFormInvalid] = useState(false);
  const [messageValidation, setMessageValidation] = useState("");

  // Control time out counters
  const [timeStart, setTimeStart] = useState(moment().set('minute', 0).set('second', 0));
  const [timeCount,  setTimeCount]  = useState(moment());
  const [flagChange, setFlagChange] = useState(false);

  // User logged in
  const user = useSelector(store => store.user.profile);

  useEffect(() => {
    console.log(location.state)
    console.log(allowChangeEmail);
    resetTimeCounters();
  }, []);

  useEffect(() => {
    /// Check if is ok for start counter
    if (timeCount.diff(timeStart, "second") === diffInSeconds)
      sendCodeVerification();
  }, [flagChange]);

  /**
   * Set initial values for time counters
   *
   */
  async function resetTimeCounters() {

    // Add difference seconds to time counter
    setTimeCount(moment(timeStart).add(counterMinutes, 'minute'));

    // Notify change in counter
    setFlagChange(flagChange => !flagChange);
  }

  /**
   * Send code verification
   *
   * @return {void}
   */
  function sendCodeVerification() {

    // Send code verification
    axios({
      method: 'post',
      url: URL+`/sendCodeVerification?id=${user.id}`,
      withCredentials: true
    });

    // Show info of send code
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Código de verificación enviado' } })

    // Start time for verification
    let interval = setInterval(() => {

      // Is time out
      if (timeCount.diff(timeStart, "second") > 0) {
        setTimeCount(timeCount.subtract(1, 'second'));
        setFlagChange(flagChange => !flagChange);
      } else
        clearInterval(interval);
    }, 1000);
  }

  /**
   * Event click button sign in
   *
   * @return {void}
   */
  function handleClick() {

    // Hide form invalid
    hideFormInvalid();

    // Validate fields empty
    if (code !== '') {

      // Send request verification code
      axios({
        method: 'post',
        url: URL+`/validateCodeVerification?cedula=${user.cedula}&codigo=${code}`,
        withCredentials: true
      })
        .then(function (response) {
          // Fail register
          if (!response.data.isValid) {
            showFormInvalid(response.data.message);
          } else {

            // Save value email verified
            dispatch(saveVerificationEmail());

            // Send view email verification completed
            history.push("/auth/completed");
          }

        })
        .catch(function (error) {
          console.log(error.message);
          showFormInvalid("Verifique su conexión a internet");
        });

    } else
      showFormInvalid("Ingrese el código de verificación");

  }

  /**
   * Back to view set email verification
   *
   */
  function changeEmail() {
    // Send view email verification
    history.push("/auth/registerEmail");
  }

  function showFormInvalid(message) {
    setFormInvalid(true);
    setMessageValidation(message);
  }
  function hideFormInvalid() {
    setFormInvalid(false);
    setMessageValidation("");
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/*<Avatar className={classes.avatar} src={logo} />*/}
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography variant="body1">
          Ingresa el código enviado a tu correo eléctronico
        </Typography>
        <Typography variant="body2">
          {user.correo} {(allowChangeEmail.allow) ? <Link href="#" onClick={() => changeEmail()}>Cambiar correo</Link> : ""}
        </Typography>
        <Typography variant="subtitle">
          <strong>Tiempo restante:</strong> {timeCount.format('mm:ss')}
          {(timeCount.diff(timeStart, "second") === 0) ? <> - <Link href="#" onClick={() => resetTimeCounters()}>Reenviar código</Link></> : ""}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="code-verification"
          label="Código de verificación"
          name="code-verification"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          error={formInvalid}
          helperText={messageValidation}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => handleClick()}
        >
          Verificar
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
