import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin  from "layouts/Admin.js";
import Parent from "layouts/Parent.js";
import Auth   from "layouts/Auth.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";

// Redux store
import { Provider, useSelector } from "react-redux";
import store from './redux';

import Utils from 'views/Utils';
import { nameShortCollege } from './variables/enviroments-files/env';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {

  const profile = useSelector(state => state.user.profile);

  const fakeAuth = {
    isAuthenticated: Object.entries(profile).length > 0,
    authenticate(cb) {
      fakeAuth.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    },
    signout(cb) {
      fakeAuth.isAuthenticated = false;
      setTimeout(cb, 100);
    }
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const hist = createBrowserHistory();

// Set title of college
document.title = `Colegio ${nameShortCollege}`;

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" component={Auth} />
        <PrivateRoute path="/admin">
          <Admin />
        </PrivateRoute>
        <PrivateRoute path="/parent">
          <Parent />
        </PrivateRoute>
        <Redirect from="/" to="/auth" />
      </Switch>
    </Router>
    <Utils />
  </Provider>,
  document.getElementById("root")
);