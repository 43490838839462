import {
  FETCHED_INITIAL_PARENT,
  FETCHED_PAYMENTS_PARENT,
  FETCHED_REGISTRATION_COSTS
} from './types';
import { FETCHED_BANKS_LIST } from '../banks/types';
import { FETCHED_EXCHANGE_RATE } from '../admin/types';
import { request } from 'graphql-request';

import { URL } from '../../../variables/enviroments-files/env';
import { doTextCompatibleWithQuery } from '../../../utils';

export function getDataParent (cedula) {

  const query = `{
    representados(cedula: "${cedula}") {
      id
      cedula
      apellidos
      nombres
      gradoletra
      deudaAnterior
      estadoDeInscripcion
      planDeCobro {
        conceptos {
          nroCuota
          codigo
          descripcion
          fechaVencimiento
          monto
          montoEnDivisa
        }
      }
    }
    pagosRepresentante (cedula: "${cedula}") {
      id
      monto
      nro_control
      es_inscripcion
      estado
      creado_en
      concepto
      transferencias {
        cedula
        nombres
        banco_id
        referencia
        monto
        fecha_emision
        estado_transferencias_id
        comentario
      }
      alumnos
    }
    bancos {
      id
      nombre_corto
    }
    costoInscripcion {
      costo_divisas
      moneda_divisa
      costo_local
      tasa_divisa_id
    }
    tasaCambio
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_INITIAL_PARENT,
        payload: data.representados
      });

      dispatch({
        type: FETCHED_PAYMENTS_PARENT,
        payload: data.pagosRepresentante
      });

      dispatch({
        type: FETCHED_BANKS_LIST,
        payload: data.bancos
      });

      dispatch({
        type: FETCHED_REGISTRATION_COSTS,
        payload: data.costoInscripcion
      });

      dispatch({
        type: FETCHED_EXCHANGE_RATE,
        payload: data.tasaCambio
      });

    })
  }
}

export function getPaymentsParent (cedula) {

  const query = `{
    pagosRepresentante (cedula: "${cedula}") {
      id
      monto
      nro_control
      es_inscripcion
      estado
      creado_en
      concepto
      transferencias {
        cedula
        nombres
        banco_id
        referencia
        monto
        fecha_emision
        estado_transferencias_id
        comentario
      }
    }
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_PAYMENTS_PARENT,
        payload: data.pagosRepresentante
      });
    })
  }
}

export function getStudentsOfParent (cedula) {

  const query = `{
    representados(cedula: "${cedula}") {
      id
      cedula
      apellidos
      nombres
      gradoletra
      deudaAnterior
      estadoDeInscripcion
      planDeCobro {
        conceptos {
          nroCuota
          codigo
          descripcion
          fechaVencimiento
          monto
          montoEnDivisa
        }
      }
    }
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_INITIAL_PARENT,
        payload: data.representados
      });
    })
  }
}

export function savePayment (PayInput) {

  // Generate string of array transfers
  let stringTransfers = "";

  for (let i=0; i < PayInput.payment.transfers.length; i++) {

    const transferNow = PayInput.payment.transfers[i];

    stringTransfers = stringTransfers + `
     { 
        cedula: "${doTextCompatibleWithQuery(transferNow.cedula)}"
        nombres: "${doTextCompatibleWithQuery(transferNow.nombres)}"
        banco_id: ${transferNow.banco_id}
        referencia: "${doTextCompatibleWithQuery(transferNow.referencia)}"
        monto: ${transferNow.monto}
        fecha_emision: "${transferNow.fecha_emision}"
      },
    `;
  }

  stringTransfers = "[" + stringTransfers + "]";

  // Generate string of array students
  let stringStudentsToPay = "[" + PayInput.payment.studentsToPay.join(",") + "]";

  // Generate string of array concepts
  let stringConceptsToPay = '["' + PayInput.payment.conceptos.join('","') + '"]';

  const query = `mutation {
    registrarPago(
      pago: {
          cedula_representante: "${PayInput.payment.cedula_representante}"
          es_inscripcion: ${PayInput.payment.esInscripcion},
          tasa_divisa_id: ${PayInput.payment.tasa_divisa_id},
          conceptos: ${stringConceptsToPay}
      },
      transferencias: ${stringTransfers},
      alumnos_pagados: ${stringStudentsToPay}
  )
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      // Consultar nuevos pagos
      dispatch(getPaymentsParent(PayInput.payment.cedula_representante));
    })
  }
}

export function checkCostAllow(date, costInput, costBadge) {

  const query = `query {
    evaluarCostosAnteriores(fecha: "${date}", costoIngresado: ${costInput}, costoEnDivisa: ${costBadge})
  }`;

  return request(URL + '/graphqll', query);
}

export function saveRegisterStudent(RegisterInput) {

  const query = `mutation {
    inscribir (
      inscripcion: {
          cedula_representante: "${RegisterInput.cedula_representante}"
          cedula_alumno:        "${RegisterInput.cedula_alumno}"
      }
    )
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      // Consultar nuevo estado de estudiantes
      dispatch(getStudentsOfParent(RegisterInput.cedula_representante));
    })
  }
}
