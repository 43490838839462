import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { getNameMonth, getNamesWithFormat } from "../../../../utils";

const moment = require('moment');

export default function Contract(props) {
  return (
    <>
      <DialogTitle id="scroll-dialog-title">Normativa de la prestación de servicios</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          El  objeto  la  prestación  de  servicios educativos  a  distancia,  a  través  del  ambiente  virtual  de  aprendizaje  de  la UECA SIGLO XXI ,  en  la  forma  de  un  periodo escolar on-line ,  con  contenido,  duración  y condiciones establecidas en la mencionada dirección electrónica.<br></br>
          El acceso al curso sólo será permitido si el  formulario de  inscripción ha sido correctamente rellenado y después de haber confirmado la seña y contraseña del usuario en el portal de la UECA SIGLO XXI.<br></br>
          Son obligaciones de la UECA SIGLO XXI:<br></br>
          Proveer el periodo para uso del ALUMNO, a través de Internet con acceso  mediante  seña  (matrícula)  y                    contraseña  específica  para  acceso  del  periodo cursado por el ALUMNO.<br></br>
          Coordinar  administrativa  y  académicamente  el  periodo cursado,  velando  por  su calidad y    por el        cumplimiento de la metodología de la educación a distancia.<br></br>
          Informar al ALUMNO, caso haya, actividades programadas para el periodo cursado.<br></br>
          Son obligaciones del ALUMNO:<br></br>
          Poseer  equipos  y  software,  siguiendo  los  requisitos  mínimos mencionados en el portal de  la UECA SIGLO XXI,  con acceso a  Internet y mantener activa una dirección electrónica permanente para contacto.<br></br>
          Enviar  las  asignaciones académicas,  caso  existan,  para  evaluación  dentro  del  plazo determinado.<br></br>
          Mantener  sus  datos  de  matrícula  actualizados  y  con  informaciones verídicas, así  como  velar por  la  confidencia de  su  seña  (matricula) y  contraseña, de manera a no permitir que sea compartida por otros.<br></br>
          El  ALUMNO  se  compromete  a  seguir  los modelos  de  conducta establecidos y vigentes en estas normas y en Internet. ABSTENIÉNDOSE DE:<br></br>
          Violar la privacidad de otros usuarios;<br></br>
          Utilizar  indebidamente  los  códigos  de  acceso  (seña/matrícula)  y  o contraseña de otros usuarios.<br></br>
          Ceder a  terceros su nombre de seña  (matrícula) y contraseña, ya que será responsable por cualquier daño o gravamen que vengan a ocasionar el mal uso de  las mismas,  debiendo  tomar  todas  las  providencias  necesarias  para  IMPEDIR  su uso por terceros. Caso la UECA SIGLO XXI tome conocimiento del acceso del alumno por otro individuo podrá bloquear el acceso, siendo tomadas las medidas establecidas en la ley.<br></br>
          Propagar virus de  computador y programas  invasivos  (worms, u otros programas de computadoras, auto-replicantes o no), que perjudiquen la operación de las redes y de los computadores individuales;<br></br>
          Intentar  burlar  el  sistema  de  seguridad  de  los  ordenadores  para  los cuales no tenga acceso;<br></br>
          Corromper o destruir datos, archivos o programas;<br></br>
          Vehicular  mensajes  que  puedan  ser  consideradas  ofensivos  y subversivos o hieran a los principios éticos.<br></br>
          La  UECA SIGLO XXI  no  se  responsabiliza  por  problemas  eventuales provenientes  de  la  interrupción  de  los  servicios  del  proveedor  de  acceso  del ALUMNO, ni por  la  interrupción de  los servicios en el caso de  falta de energía eléctrica para el sistema de su proveedor de acceso, fallas en el sistema de trasmisión o  de  navegación  en  el  acceso  a  internet,  incompatibilidad  de  los  sistemas  de  los usuarios con  los del proveedor de acceso o cualquier acción de terceros que  impidan la prestación del servicio resultante de caso fortuito o de fuerza mayor relacionados en el Código Civil Venezolano.


        </DialogContentText>
      </DialogContent>
    </>
  );
}