import {
  FETCHED_TRANSFERS_LIST
} from '../../actions/transfer/types';

const initialState = {
  rows: [],
  nro_paginas:   1,
  pagina_actual: 1
};

export default function (state = initialState, action) {

  let result;

  switch (action.type) {
    case FETCHED_TRANSFERS_LIST:
      result = {
        ...state,
        rows: action.payload.rows,
        nro_paginas: action.payload.nro_paginas,
        pagina_actual: action.payload.pagina_actual
      };
      break;
    default:
      result = state;
      break;
  }

  return result;
}