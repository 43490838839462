// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";

// core components/views for Admin role
import Aspirants from "views/Roles/Admin/Aspirant";
import Payments from "views/Roles/Admin/Payments";
import Users from "views/Roles/Admin/Users";

// core components/views for Parent role
import Summary from "views/Roles/Parent/Summary";
import Checkout from "views/Roles/Parent/Register/Checkout";

export const adminRoutes = [
  {
    path: "/dashboard",
    name: "Tablero",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/aspirants",
    name: "Aspirantes",
    icon: "content_paste",
    component: Aspirants,
    layout: "/admin"
  },
  {
    path: "/payments",
    name: "Transferencias",
    icon: "payment",
    component: Payments,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: "people",
    component: Users,
    layout: "/admin"
  }
];

export const parentRoutes = [
  {
    path: "/summary",
    name: "",
    icon: Dashboard,
    component: Summary,
    layout: "/parent"
  },
  {
    path: "/register",
    name: "",
    icon: Dashboard,
    component: Checkout,
    layout: "/parent"
  }
];
