import {
  FETCHED_INITIAL_PARENT,
  ADD_TRANSFERS_PARENT,
  CLEAR_TRANSFERS_PARENT,
  FETCHED_PAYMENTS_PARENT,
  FETCHED_REGISTRATION_COSTS
} from '../../actions/parent/types';

const initialState = {
  students:  [],
  transfers: [],
  payments: [],
  registrationCost: {
    costo_divisas:  0,
    moneda_divisa:  "USD",
    costo_local:    0,
    tasa_divisa_id: 1
  }
};

export default function (state = initialState, action) {

  let result;

  switch (action.type) {
    case FETCHED_INITIAL_PARENT:
      result = {
        ...state,
        students: action.payload
      };
      break;
    case ADD_TRANSFERS_PARENT:

      let transfers = state.transfers;
      transfers.push(action.payload);

      result = {
        ...state,
        transfers: transfers
      };
      break;
    case CLEAR_TRANSFERS_PARENT:
      result = {
        ...state,
        transfers: []
      };
      break;
    case FETCHED_PAYMENTS_PARENT:
      result = {
        ...state,
        payments: action.payload
      };
      break;
    case FETCHED_REGISTRATION_COSTS:
      result = {
        ...state,
        registrationCost: action.payload
      };
      break;
    default:
      result = state;
      break;
  }

  return result;
}