import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import Switch from "@material-ui/core/Switch";
import Pagination from "@material-ui/lab/Pagination";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";

import { getAspirants, changeStatusAspirant, allowRegisterWithDebt } from "../../../redux/actions/admin";
import { changeAccess } from "../../../redux/actions/user";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from '@material-ui/icons/Assignment';

import { getNamesWithFormat } from '../../../utils';
import { NumberFormatSimple } from '../../Utils/NumberFormat'
import TextField from "@material-ui/core/TextField";
import {getMountsOfConcepts, getStatusForStudent, haveConceptsOverdue} from "../../../models/Student";
import { editDebtAspirant } from "../../../redux/actions/admin";

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
      color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
    },
  },

  formControl: {
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 35,
    height: 21,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function Aspirants() {

  const classes = useStyles();

  const dispatch = useDispatch();

  const [detailsDialog, setDetailsDialog]       = useState(false);
  const [indexNowAspirant, setIndexNowAspirant] = useState(0);

  const [searchCedulaStudent, setSearchCedulaStudent] = useState("");
  const [searchLastNameStudent, setSearchLastNameStudent] = useState("");

  const aspirants = useSelector((state) => state.admin.aspirants.rows);
  const nroPaginates = useSelector(
    (state) => state.admin.aspirants.nro_paginas
  );
  const periodNow = useSelector(state => state.utils.period);

  const [currentPage, setCurrentPage] = useState(1);

  const isFiltering = searchCedulaStudent !== "" && searchLastNameStudent !== "";

  // Search registers paginated
  useEffect(() => searchAspirants(), [currentPage]);

  // Search if empty filter cedula
  useEffect(() => {
    if (!isFiltering)
      searchAspirants()
  }, [searchCedulaStudent]);

  /**
   * Search aspirants
   *
   * @return {void}
   */
  function searchAspirants() {

    // Disabled pagination with filter
    const currentPageEvaluated = (isFiltering) ? 1 : currentPage;

    // Show info of searching
    dispatch({ type: "SHOW_SNACKBAR", payload: { message: "Buscando..." } });

    // Send request
    dispatch(getAspirants(currentPageEvaluated, searchCedulaStudent, searchLastNameStudent));
  }

  function getAspirantsFormated() {
    return aspirants.map((aspirant, index) => [
      aspirant.cedula,
      getNamesWithFormat(aspirant.apellidos),
      getNamesWithFormat(aspirant.nombres),
      aspirant.gradoletra,
      getNamesWithFormat(aspirant.nombresRepresentante),
      getStatusForStudent(aspirant, true),
      <>
        <Tooltip title="Detalles">
          <IconButton
            color="primary"
            onClick={() => openDetailsOfStudent(index)}
          >
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      </>
    ]);
  }

  /**
   * Open view dialog component of details student
   *
   * @return {void}
   */
  function openDetailsOfStudent(index) {
    setIndexNowAspirant(index);
    setDetailsDialog(true);
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Matrícula general</h4>
              <p className={classes.cardCategoryWhite}>
                Listado de aspirantes para el período {periodNow.first_year}-{periodNow.second_year}
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4} style={{maxWidth: "20em"}}>
                  <Paper component="form" className={classes.root}>
                    <InputBase
                      style={{marginLeft: "1em"}}
                      className={classes.input}
                      placeholder="Buscar por cédula"
                      inputProps={{ 'aria-label': 'search google maps' }}
                      value={searchCedulaStudent}
                      onChange={(event) => setSearchCedulaStudent(event.target.value)}
                    />
                    <IconButton className={classes.iconButton} aria-label="search" onClick={() => searchAspirants()}>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{maxWidth: "20em"}}>
                  <Paper component="form" className={classes.root}>
                    <InputBase
                      style={{marginLeft: "1em"}}
                      className={classes.input}
                      placeholder="Buscar por apellidos"
                      inputProps={{ 'aria-label': 'search google maps' }}
                      value={searchLastNameStudent}
                      onChange={(event) => setSearchLastNameStudent(event.target.value)}
                    />
                    <IconButton className={classes.iconButton} aria-label="search" onClick={() => searchAspirants()}>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </GridItem>
              </GridContainer>
              <Table
                tableHeaderColor="info"
                tableHead={[
                  "Cédula",
                  "Apellidos",
                  "Nombres",
                  "Grado",
                  "Representante",
                  "Estado",
                  "-"
                ]}
                tableData={getAspirantsFormated()}
              />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {(!isFiltering) ?
                <Pagination
                  count={nroPaginates}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  style={{ marginTop: "1em" }}
                /> : ""}
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Modal details of student */}
      <DetailsDebtStudent open={detailsDialog} aspirant={aspirants[indexNowAspirant]} onClose={() => setDetailsDialog(false)} nro_pagina_now={currentPage} />
    </>
  );
}


/**
 * Componente of details Student Pay
 *
 */
function DetailsDebtStudent(props) {

  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    open,
    onClose,
    getStatus,
    aspirant = {
      id: 0,
      cedula: "",
      apellidos: "",
      nombres: "",
      nombresRepresentante: "",
      deudaAnterior: 0,
      estadoDeInscripcion: "",
      bloqueado: "0",
      autorizacion: null
    },
    nro_pagina_now = 1
  } = props;

  useEffect(() => {
    const status = (aspirant.estadoDeInscripcion === "1") ? "false" : "true";
    setAllowRegister(status);
  }, [aspirant]);


  useEffect(() => {
    const status = (aspirant.bloqueado === "1") ? "true" : "false";
    setAllowAccess(status);
  }, [aspirant]);

  const user = useSelector(state => state.user.profile);
  const users = useSelector(state => state.user.rows);
  const exchangeRateMount = useSelector(store => store.admin.exchangeRate);
  const registrationCost = useSelector(store => store.parent.registrationCost.costo_local);

  const [allowRegister, setAllowRegister]                           = useState("true");
  const [allowAccess, setAllowAccess]                               = useState("true");
  const [confirmAuthorization, setConfirmAuthorization]             = useState(false);
  const [disabledFieldAllowRegister, setDisabledFieldAllowRegister] = useState(false);
  const [dialogModifyDebt, setDialogModifyDebt]                     = useState(false);

  /**
   * Details student
   *
   */
  function getMountToPay(aspirant) {

    const isRegisteredCancelled = aspirant.estadoDeInscripcion === "3" || aspirant.estadoDeInscripcion === "4";

    // Check debt
    if (aspirant.deudaAnterior > 0)
      return (aspirant.deudaAnterior * exchangeRateMount);
    // Register cancelled
    else if (!isRegisteredCancelled)
      return registrationCost;
    // Have concepts pending
    else if (haveConceptsOverdue(aspirant.planDeCobro.conceptos))
      return getMountsOfConcepts(aspirant.planDeCobro.conceptos);
    else
      return 0;

  }

  function handleClose() {
    onClose();
  }

  function handleRadioChange(event) {
    // Update value in UI
    setAllowRegister(event.target.value);

    // Show alert sending request
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Actualizando...' } });

    // Formatted new value for API
    const newValueStatus = (event.target.value === "true") ? "0" : "1";

    // Send change to API
    dispatch(changeStatusAspirant(aspirant.id, newValueStatus, nro_pagina_now));
  }

  function handleRadioAccessChange(event) {
    // Update value in UI
    setAllowAccess(event.target.value);

    // Show alert sending request
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Actualizando...' } });

    // Send change to API
    dispatch(changeAccess(aspirant.cedula, nro_pagina_now));
  }

  function openDialogConfirmAuthorization() {
    setConfirmAuthorization(true);
  }

  function getStatusAuthorization() {
    if (aspirant.autorizacion && aspirant.deudaAnterior === 0) {
      let autorizadoPor = users.filter(user => Number.parseInt(user.id) === aspirant.autorizacion.administrador_id)[0].nombres;
      return (
      <>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <strong>Autorizado a inscribir por: </strong>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            {getNamesWithFormat(autorizadoPor)}
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <strong>Deuda autorizada: </strong>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <NumberFormatSimple number={aspirant.autorizacion.deuda_cancelada} /> Bs.
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <strong>Fecha de autorización: </strong>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            {moment(aspirant.autorizacion.creado_en).format("DD-MM-YYYY")}
          </GridItem>
        </GridContainer>
      </>);
    } else if (aspirant.deudaAnterior > 0)
      return <Button variant="outlined" color="primary" className={classes.button} onClick={() => openDialogConfirmAuthorization()}>Autorizar inscripción con deuda</Button>;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Detalles</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <strong>Estudiante: </strong>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
                {getNamesWithFormat(aspirant.nombres + " " + aspirant.apellidos)} - {aspirant.cedula}
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <strong>Estado: </strong>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
                {getStatusForStudent(aspirant)}
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <strong>Monto (Bs.): </strong>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
                <NumberFormatSimple number={getMountToPay(aspirant)} />
              </GridItem>
            </GridContainer>
            {(aspirant.estadoDeInscripcion !== "3" && aspirant.estadoDeInscripcion !== "4") ?
            <GridContainer>
              <GridItem>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel>Permitir proceso de inscripción</FormLabel>
                  <RadioGroup row aria-label="quiz" name="quiz" value={allowRegister} onChange={handleRadioChange}>
                    <FormControlLabel value="true" control={<Radio color="primary" disabled={disabledFieldAllowRegister} />} label="Si" />
                    <FormControlLabel value="false" control={<Radio color="primary" disabled={disabledFieldAllowRegister} />} label="No" />
                  </RadioGroup>
                  {(allowRegister === "true") ?
                    <>
                      {getStatusAuthorization()}
                    </>
                    : ""
                  }
                </FormControl>
              </GridItem>
            </GridContainer> : ""}
            <GridContainer>
              <GridItem>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel>Bloquear acceso al Aula virtual</FormLabel>
                  <RadioGroup row aria-label="quiz" name="quiz" value={allowAccess} onChange={handleRadioAccessChange}>
                    <FormControlLabel value="true" control={<Radio color="primary" />} label="Si" />
                    <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                  {(aspirant.deudaAnterior == 0) ? 
                    <Button variant="outlined" color="primary" className={classes.button} style={{ width: 'fit-content' }} onClick={() => setDialogModifyDebt(true)}>Crear deuda</Button> 
                    : <Button variant="outlined" color="primary" className={classes.button} style={{ width: 'fit-content' }} onClick={() => setDialogModifyDebt(true)}>Modificar deuda</Button>}
                </FormControl>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Volver
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmAuthorization open={confirmAuthorization} aspirant={aspirant} nro_pagina_current={nro_pagina_now} exchangeRateMount={exchangeRateMount} onClose={() => setConfirmAuthorization(false)} />
      <ModifyMountDebt open={dialogModifyDebt} aspirant={aspirant} nro_pagina_current={nro_pagina_now} exchangeRateMount={exchangeRateMount} onClose={() => setDialogModifyDebt(false)} />
    </>
  );
}

/**
 * Confirm dialog of Authorization
 *
 */
function ConfirmAuthorization(props) {

  const dispatch = useDispatch();

  const {
    open,
    aspirant = {
      id: 0,
      apellidos: "",
      nombres: "",
      deudaAnterior: 0
    },
    nro_pagina_current = 0,
    exchangeRateMount = 0,
    onClose
  } = props;

  const user = useSelector((state) => state.user.profile);
  const periodNow = useSelector(state => state.utils.period);

  function handleClose() {
    onClose();
  }

  function handleSubmit() {

    // Show alert sending request
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Actualizando...' } });

    // Send request
    dispatch(allowRegisterWithDebt(aspirant.id, user.id, nro_pagina_current))

    handleClose();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Estas seguro?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Yo <strong>{getNamesWithFormat(user.nombres)}</strong> autorizo al alumno <strong>{getNamesWithFormat(aspirant.nombres) + ' ' + getNamesWithFormat(aspirant.apellidos)}</strong> a participar en el proceso de
            inscripción {periodNow.first_year}-{periodNow.second_year} teniendo una deuda pendiente de <strong>{<NumberFormatSimple number={(aspirant.deudaAnterior * exchangeRateMount)} />}</strong> Bs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Descartar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * Component edit mount debt
 *
 */
function ModifyMountDebt(props) {

  const {
    open,
    aspirant = {
      id: 0,
      apellidos: "",
      nombres: "",
      deudaAnterior: 0
    },
    nro_pagina_current = 0,
    exchangeRateMount = 0,
    onClose
  } = props;

  const dispatch = useDispatch();

  const [mount, setMount] = React.useState(0);

  const handleSubmit = () => {

    // Validate field
    if (mount === 0) {
      // Show alert of validation
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Ingrese el total de la nueva deuda', type: 'warning' } })

      return;
    }

    const ExchangeRateInput = {
      mount: mount,
      aspirantId: aspirant.id,
      nro_pag_now: nro_pagina_current
    }

    dispatch(editDebtAspirant(ExchangeRateInput));

    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Enviando...' } });

    onClose();
  }

  return (
    <div>
      <Dialog open={props.open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{(aspirant.deudaAnterior > 0) ? 'Modificar' : 'Crear'} deuda pendiente</DialogTitle>
        <DialogContent>
          {(aspirant.deudaAnterior > 0) ?
          <>
            <DialogContentText>
              <strong>Deuda actual en divisas:</strong> {aspirant.deudaAnterior}
            </DialogContentText>
            <DialogContentText>
              <strong>Deuda actual (Bs.):</strong> {aspirant.deudaAnterior * exchangeRateMount}
            </DialogContentText></> : <></>}
          <DialogContentText>
            Recuerde que el monto nuevo a ingresar <strong>debe ser expresado en la divisa correspondiente.</strong>
          </DialogContentText>
          <DialogContentText>
            La definición de deudas imposibilita al alumno generar pagos correspondientes a inscripción
            y conceptos del semestre hasta saldarla.
          </DialogContentText>
          {(aspirant.deudaAnterior > 0) ?
          <DialogContentText>
            <strong>Importante: </strong> Modificar la deuda actual sobreescribira la anterior sin posibilidad de recuperarla.
          </DialogContentText> : <></>}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Monto en divisas"
            type="number"
            min="0"
            value={mount}
            onChange={(event) => setMount(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
