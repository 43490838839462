import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import { useDispatch } from 'react-redux';
import { saveRegisterStudent } from '../../../redux/actions/parent';

import Contract from './Contracts/Contract'

export default function AceptTerms(props) {

  const {
    student = {
      id: 0,
      cedula: "",
      nombres: "",
      apellidos: "",
      gradoletra: ""
    },
    user = {
      cedula: "",
      nombres: ""
    },
    downloadContract
  } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    // Create event emit task
    let event = new CustomEvent("closeRegisterTermsForm");

    // Emit event
    window.dispatchEvent(event);
  };

  /**
   * Download sample contract
   *

  const handleDownload = () => {
    // Download PDF contract
    downloadContract(student.id);
  };
  */

  /**
   * Acept terms for register
   *
   */
  const handleSubmit = () => {

    // End process register student
    dispatch(saveRegisterStudent({
      cedula_representante: user.cedula,
      cedula_alumno:        student.cedula
    }));

    // Show info end process
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Registro de inscripción de ' + student.nombres + ' culminado' } })

    // Close view terms
    handleClose();
  };

  return (
    <>
    <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Contract student={student} user={user} />
          <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Acepto
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}