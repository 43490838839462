import Icon from "@material-ui/core/Icon";
import { inactiveMessage } from "../variables/enviroments-files/env";
import React from "react";

const moment = require('moment');

/**
 * Get concept description
 *
 * @param {object} student
 * @param {array} concepts
 * @return {string}
 */
export function getConceptDescription(student, concepts, period) {
  const lapso_text = (period) ? `${period.first_year}-${period.second_year}` : '';
  if (student.deudaAnterior > 0) {
    return `Deuda de lapso anterior`;
  } else if (student.estadoDeInscripcion !== "3" && student.estadoDeInscripcion !== "4") {
    return `Derecho a inscripción (${lapso_text})`;
  } else if (haveConceptsOverdue(student.planDeCobro.conceptos) && student.estadoDeInscripcion !== "3") {
    return getDescriptionOfConcepts(concepts);
  } else {
    return "---";
  }
}

/**
 * Check if have concepts overdue
 *
 * @param {array} concepts
 */
export function haveConceptsOverdue(concepts) {
  return (concepts.filter(concept => (moment(concept.fechaVencimiento).isSameOrBefore(moment()))).length > 0);
}

/**
 * Get description concepts overdue
 *
 * @param {array} concepts
 */
export function getConceptsOverdue(concepts) {
  return concepts.filter(concept => (moment(concept.fechaVencimiento).isSameOrBefore(moment())));
}

/**
 * Get descripcion in the concepts
 *
 * @param {array} concepts
 * @return {string}
 */
export function getDescriptionOfConcepts(concepts) {

  // Reduce much concepts in overdue
  if (concepts.length > 7) {

    concepts = concepts.slice(0, 6);

    // Add decoraton end array
    concepts.push({descripcion: '...'});
  }

  return concepts.map(concept => concept.descripcion).join(", ");
}

/**
 * Get status of student
 *
 * @param {object} student
 * @param {boolean} forViewAdmin
 */
export function getStatusForStudent(student, forViewAdmin = false) {

  const isRegistered = student.estadoDeInscripcion === "4";

  if (student.deudaAnterior > 0)
    return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Deuda pendiente</>
  else if ("planDeCobro" in student && haveConceptsOverdue(student.planDeCobro.conceptos) && isRegistered)
    return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Cuota(s) vencida(s)</>
  else {
    switch (student.estadoDeInscripcion) {
      case "1":
        return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> { forViewAdmin ? "Inscripción suspendida" : (inactiveMessage || "Comunicarse con administración") }</>
      case "3":
        return <><Icon style={{ fontSize: "0.6em", color: "#ffc107" }} fontSize="small">lens</Icon> Resta confirmar inscripción</>
      case "4":
        return <><Icon style={{ fontSize: "0.6em", color: "#2dce89" }} fontSize="small">lens</Icon> Solvente</>
      default:
        return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Resta cancelar inscripción</>
    }
  }
}

/**
 * Calculate totally mount of concepts overdue
 *
 * @param {array} concepts
 * @return {float}
 */
export function getMountsOfConcepts(concepts) {

  let totallyMount = 0;

  concepts.forEach(concept => {
    if (moment(concept.fechaVencimiento).isSameOrBefore(moment()))
      totallyMount += concept.monto;
  });

  return totallyMount;
}
