  import React, { useEffect } from "react";

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";

import { getInitialDay } from "../../utils";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { useSelector } from "react-redux";

import { NumberFormatSimple } from '../Utils/NumberFormat'

const useStyles = makeStyles(styles);

const moment = require('moment');

const Chartist = require("chartist");

export default function Dashboard() {

  const classes = useStyles();

  const metrics = useSelector(state => state.admin.metrics);

  function getWeekDays() {

    const dateNow = moment();
    const week = [];
    const dayIterator = dateNow;

    week.push(getInitialDay(dayIterator.day()));

    for (let i = 0; i < 6; i++) {
      week.unshift(getInitialDay(dayIterator.subtract(1, 'day').day()));
    }

    return week;
  }

  function getMajorValue(week) {

    let major = 0;

    week.forEach(value => {
      if (value > major)
        major = value
    });

    return major + 10;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>assignment_turned_in</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Inscritos</p>
              <h3 className={classes.cardTitle}>
                {metrics.nro_inscritos}/{metrics.nro_aspirantes}
              </h3>
            </CardHeader>
            <CardFooter stats>
              {/*<div className={classes.stats}>
                <Icon>assignment_turned_in</Icon>
                <span className={classes.textFooter}>
                  Ver listado de inscritos
                </span>
              </div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>payments</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Ingresos</p>
              <h3 className={classes.cardTitle}><NumberFormatSimple number={metrics.monto_ingresos} /></h3>
            </CardHeader>
            <CardFooter stats>
              {/*<div className={classes.stats}>
                <Icon>payments</Icon>
                <span className={classes.textFooter}>
                  Ver pagos procesados
                </span>
              </div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Morosos</p>
              <h3 className={classes.cardTitle}>{metrics.nro_morosos}</h3>
            </CardHeader>
            <CardFooter stats>
              {/* <div className={classes.stats}>
                <Icon>info_outline</Icon>
                <span className={classes.textFooter}>
                  Ver listado de morosos
                </span>
              </div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Followers</p>
              <h3 className={classes.cardTitle}>+245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: getWeekDays(),
                  series: [metrics.grafica_inscritos]
                }}
                type="Bar"
                options={{
                  axisX: {
                    showGrid: false
                  },
                  low: 0,
                  high: getMajorValue(metrics.grafica_inscritos),
                  chartPadding: {
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 0
                  }
                }}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Alumnos inscritos</h4>
              {/*<p className={classes.cardCategory}>Relación de inscriptos en la última semana</p>*/}
            </CardBody>
            <CardFooter chart>
              {/*<div className={classes.stats}>
                <AccessTime /> Actualizado el día de hoy
              </div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: getWeekDays(),
                  series: [metrics.grafica_ingresos]
                }}
                type="Line"
                options={{
                  lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                  }),
                  low: 0,
                  high: getMajorValue(metrics.grafica_ingresos), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                  chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                  }
                }}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Transferencias procesadas</h4>
              {/*<p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                de incremento en los pagos
              </p>*/}
            </CardBody>
            <CardFooter chart>
              {/*<div className={classes.stats}>
                <AccessTime /> Actualizado el día de hoy
              </div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: getWeekDays(),
                  series: [metrics.grafica_solvencias]
                }}
                type="Line"
                options={{
                  lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                  }),
                  low: 0,
                  high: getMajorValue(metrics.grafica_solvencias), // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                  chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                  }
                }}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Deudas saldadas</h4>
              {/*<p className={classes.cardCategory}>Solvencias procesadas en la última semana</p>*/}
            </CardBody>
            <CardFooter chart>
              {/*<div className={classes.stats}>
                <AccessTime /> Actualizado hace dos dias
              </div>*/}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/*<GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
              <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Name", "Salary", "Country"]}
                tableData={[
                  ["1", "Dakota Rice", "$36,738", "Niger"],
                  ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                  ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                  ["4", "Philip Chaney", "$38,735", "Korea, South"]
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>*/}
    </div>
  );
}
