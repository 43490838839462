import {
  FETCHED_USER_PROFILE,
  ADD_USER_PROFILE,
  ADD_EMAIL_USER_PROFILE,
  ADD_EMAIL_VERIFIED_USER_PROFILE,
  LOGOUT_USER_PROFILE, FETCHED_USERS_LIST
} from '../../actions/user/types';

const initialState = {
  profile: {},
  loggedIn: false,
  rows: []
};

export default function (state = initialState, action) {

  let result;
  let profileNow;

  switch (action.type) {
    case FETCHED_USER_PROFILE:
      result = {
        ...state,
        profile: action.payload
      };
      break;
    case FETCHED_USERS_LIST:
      result = {
        ...state,
        rows: action.payload
      };
      break;
    case ADD_USER_PROFILE:
      result = {
        ...state,
        profile: action.payload,
        loggedIn: true
      };
      break;
    case ADD_EMAIL_USER_PROFILE:

      // Add new email
      profileNow = state.profile;
      profileNow.correo = action.payload;

      result = {
        ...state,
        profile: profileNow,
      };
      break;
    case ADD_EMAIL_VERIFIED_USER_PROFILE:

      // Add new status email verified
      profileNow = state.profile;
      profileNow.correoVerificado = true;

      result = {
        ...state,
        profile: profileNow,
      };
      break;
    case LOGOUT_USER_PROFILE:
      result = {
        ...state,
        profile: action.payload,
        loggedIn: false
      };
      break;
    default:
      result = state;
      break;
  }

  return result;
}