import { FETCHED_TRANSFERS_LIST } from '../transfer/types';
import { request } from 'graphql-request';

import { URL } from '../../../variables/enviroments-files/env';
import { doTextCompatibleWithQuery } from '../../../utils';

export function getTransfers (fecha_inicio, fecha_fin, nroPage = 1) {

  const query = `{
    transferencias (fecha_inicio: "${fecha_inicio}", fecha_fin: "${fecha_fin}" nro_pagina: ${nroPage}) {
      rows {
        id
        cedula
        nombres
        banco_id
        referencia
        monto
        fecha_emision
        estado_transferencias_id
      }
      nro_paginas
      pagina_actual
    }
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      dispatch({
        type: FETCHED_TRANSFERS_LIST,
        payload: data.transferencias
      });

      dispatch({ type: 'HIDE_SNACKBAR' });
    })
  }
}

export function changeStatusTransfer(id, status, rangeDate, currentPage, comment = null) {

  const editComment = (comment) ? `, comentario: "${doTextCompatibleWithQuery(comment)}"` : '';

  const query = `mutation {
    cambiarEstadoTransferencia (id: ${id}, estado: ${status} ${editComment})
  }`;

  return dispatch => {
    request(URL + '/graphqll', query).then(data => {

      // Consultar nueva estado de transferencias
      dispatch(getTransfers(rangeDate[0], rangeDate[1], currentPage));
    })
  }
}