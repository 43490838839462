import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// Styles view
import { makeStyles } from '@material-ui/core/styles';

// Elements UI
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from "../../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import GridItem from "../../../components/Grid/GridItem";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from "../../../components/Grid/GridContainer";
import DialogContentText from '@material-ui/core/DialogContentText';


// Icons UI
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// Date field utils
import 'date-fns';
import moment from 'moment';

import { savePayment, checkCostAllow } from '../../../redux/actions/parent';

import { getNamesWithFormat } from "../../../utils";
import { NumberFormatSimple } from '../../Utils/NumberFormat';
import { getConceptDescription } from "../../../models/Student";
import { bankSelectorLabel, showBankDetails } from "../../../variables/enviroments-files/env"
import ShowBankData from "./BankData/ShowBankData";

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    minWidth: "100%",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function RegisterPay(props) {

  // Styles
  const classes = useStyles();

  // Default values for properties
  const { conceptsToPay = [] } = props

  // Control open form register tranfer
  const [openRegisterTransfer, setOpenRegisterTransfer] = React.useState(false);

  // Get list of transfers added
  const transfers = useSelector(state => state.parent.transfers);

  // Get list banks of store
  const banks = useSelector(state => state.banks.rows);

  // Get exchange rate ID
  const exchangeRateID = useSelector(state => state.parent.registrationCost.tasa_divisa_id);

  // Get registration cost in exchange rate
  const exchangeRateMount = useSelector(state => state.parent.registrationCost.costo_divisas);

  // Get exchange rate mount
  const exchangeRate = useSelector(store => store.admin.exchangeRate);

  const dispatch = useDispatch();

  /**
   * Get students of parent
   *
   */
  function getStudents() {

    let studentsFilter;

    // Format student list
    studentsFilter = props.students.map((student) => [
      student.cedula,
      getNamesWithFormat(student.nombres),
      student.gradoletra,
      getConceptDescription(student, conceptsToPay),
      getMountToPay(student)
    ]);

    // Calculate totally mount
    let totallyMount = getTotallyMountToPay();

    // Add totally mount
    studentsFilter.push([<><strong>Total</strong></>, "", "", "", <><strong><NumberFormatSimple number={totallyMount} /></strong></>]);

    return studentsFilter;
  }

  /**
   * Get mount to pay for student
   *
   */
  function getMountToPay(student) {

    // Check debt
    if (props.isDebt) {
      return <NumberFormatSimple number={(student.deudaAnterior * exchangeRate)} />;
    // Check register cancelled
    } else {
      return <NumberFormatSimple number={props.mountToPay} />;
    }

  }

  /**
   * Get totally mount to pay
   *
   */
  function getTotallyMountToPay() {

    let totallyMount = 0;

    // Check debt
    if (props.isDebt) {
      props.students.forEach(student => {
        totallyMount = totallyMount + Number.parseFloat((student.deudaAnterior * exchangeRate))
      });
    // If registration cost
    } else {
      props.students.forEach(() => {
        totallyMount = (totallyMount + props.mountToPay);
      });
    }

    return totallyMount;
  }

  /**
   * Filter tranfers added
   *
   * @return {array}
   */
  function getTransfers() {

    // Calculate totally mount
    let totallyMount = 0;
    transfers.forEach(transfer => {
      totallyMount = totallyMount + Number.parseFloat(transfer[5])
    });

    // Set format view for transfers
    let transfersFilter = (transfers.length > 0) ? transfers.map(transfer => [
      transfer[0],
      transfer[1],
      transfer[2],
      transfer[3],
      getNameBank(Number.parseInt(transfer[4])),
      <NumberFormatSimple number={Number.parseFloat(transfer[5])} />
    ]): [["-", "-", "-", "-", "-", "-"]];

    // Add totally mount
    transfersFilter.push([<><strong>Total</strong></>, "", "", "", "", <><strong><NumberFormatSimple number={totallyMount} /></strong></>]);

    return transfersFilter;
  }

  /**
   * Get name of bank
   *
   * @param  {int}   id
   * @return {string}
   */
  function getNameBank(id) {

    const result = banks.filter(bank => Number.parseInt(bank.id) === id);

    return (result.length > 0) ? result[0].nombre_corto : "-";
  }

  /**
   * Emit event close form
   *
   */
  const handleClose = () => {

    // Clear transfers added
    dispatch({ type: 'CLEAR_TRANSFERS_PARENT' })

    // Close component
    props.onClose();
  };

  /**
   * Open modal register transfer
   *
   */
  function openRegisterTranferForm() {

    // Show form register transfer
    setOpenRegisterTransfer(true);

    // Listen event close form
    window.addEventListener("closeRegisterTranferForm", () => setOpenRegisterTransfer(false));
  }

  /**
   * Send pay register
   *
   */
  async function handleSubmit() {

    // Validate input transfers?
    if (transfers.length === 0) {
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Ingrese las transferencias correspondientes al pago', type: 'warning' } });
      return;
    }

    // Validate mount valid
    if (!isDebtCancelled()) {
      const result = await evaluateBeforeCost();
      // Mount reproved
      if (!result) {
        showAlertInvalidMount();
        return;
      }
    }

    // Set formated transfers
    let transfersFormated = transfers.map(transfer => ({
      cedula:        transfer[0],
      nombres:       transfer[1],
      banco_id:      transfer[4],
      referencia:    transfer[2],
      monto:         transfer[5],
      fecha_emision: transfer[3]
    }));

    // Set formated payment
    const PaymentInput = {
      payment: {
        cedula_representante: props.user.cedula,
        esInscripcion:        (props.isDebt) ? 0 : 1,
        transfers:            transfersFormated,
        tasa_divisa_id:       exchangeRateID,
        studentsToPay:        (!props.isDebt) ? props.students.map(student => student.id) : [],
        conceptos:            getCodesToConceptsToPay(conceptsToPay)
      },
    };

    // Register payment
    dispatch(savePayment(PaymentInput));

    // Show alert validation
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Pago registrado exitosamente', type: 'success' } });

    // Close view
    handleClose();
  }

  /**
   * Check if debt is cancelled
   *
   */
  function isDebtCancelled() {

    let totallyMountDebt = 0;
    let totallyMountTransfers = 0;

    transfers.forEach(transfer => {
      totallyMountTransfers = totallyMountTransfers + Number.parseFloat(transfer[5])
    });

    if (props.isDebt) {
      props.students.forEach(student => {
        totallyMountDebt = totallyMountDebt + Number.parseFloat((student.deudaAnterior * exchangeRate))
      });
    } else {
      props.students.forEach(student => {
        totallyMountDebt = (totallyMountDebt + props.mountToPay);
      });
    }

    return (totallyMountTransfers.toFixed(2) === totallyMountDebt.toFixed(2));
  }

  /**
   * Show alert for invalid mount
   *
   */
  function showAlertInvalidMount() {
    // Show alert validation
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'El total de las transferencias debe ser igual al total de la deuda', type: 'warning' } });
  }

  /**
   * Evaluate cost before for register
   *
   */
  async function evaluateBeforeCost() {

    // Extract date major
    let majorDate = moment(transfers[0][3]);
    transfers.forEach(transfer => {
      majorDate = (majorDate.isAfter(transfer[3])) ? majorDate : moment(transfer[3]);
    });

    // Evaluated types of payment
    const isRegisterStudent = props.students.filter(student => student.estadoDeInscripcion === "3").length > 0;

    // Extract cost input for payment
    let costInput = 0;
    let costInputBadge = 0;

    // Check type payment
    if (props.isDebt) {
      // Get costs input for debt cost
      costInput = getMountInputForConcepts();
      costInputBadge = props.students[0].deudaAnterior;
    } else if (isRegisterStudent) {
      // Get costs input for registration cost
      costInput = getMountInputForRegister();
      costInputBadge = exchangeRateMount;
    } else {
      // Get costs input for concepts selected
      costInput = getMountInputForConcepts();
      costInputBadge = getMountBadgeForConcepts();
    }

    // Show message send request
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Evaluando...' } });

    // Send request
    const response = await checkCostAllow(majorDate.format('YYYY-MM-DD'), costInput, costInputBadge);

    dispatch({ type: 'HIDE_SNACKBAR' });

    // Check response
    if ("evaluarCostosAnteriores" in response) {
      return response.evaluarCostosAnteriores;
    } else {
      showAlertInvalidMount();
      return false;
    }
  }

  /**
   * Get input mount for register student
   *
   * @return {number}
   */
  function getMountInputForRegister() {

    let totallyMountTransfers = 0;

    transfers.forEach(transfer => {
      totallyMountTransfers = totallyMountTransfers + Number.parseFloat(transfer[5]);
    });

    return (totallyMountTransfers / props.students.length);
  }

  /**
   * Get input mount for concepts selected
   *
   * @return {number}
   */
  function getMountInputForConcepts() {

    let totallyMountTransfers = 0;

    transfers.forEach(transfer => {
      totallyMountTransfers = totallyMountTransfers + Number.parseFloat(transfer[5]);
    });

    return totallyMountTransfers;
  }

  /**
   * Get badge mount for concepts selected
   *
   * @return {number}
   */
  function getMountBadgeForConcepts() {

    let totallyMount = 0;

    conceptsToPay.forEach(concept => {
      totallyMount = (totallyMount + concept.montoEnDivisa);
    });

    return totallyMount;
  }

  /**
   * Get codes of concepts to pay
   *
   * @param {array} conceptsToPay
   * @return {array}
   */
  function getCodesToConceptsToPay(conceptsToPay) {

    // Check if have concepts
    if (conceptsToPay.length === 0)
      return [];

    // Extract codes to concepts to pay
    let codesOfConcepts = [];
    conceptsToPay.forEach(concept => codesOfConcepts.push(concept.codigo));

    return codesOfConcepts
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Registrar pago</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A continuación, debera ingresar la(s) transferencia(s) correspondientes al total de la deuda reflejada para procesar el pago.
            <br/>
            {(!props.isDebt) ? <><strong>Nota: </strong>Los montos están sujetos a ajustes diarios.</> : ""}
            <br/><br/>

            {/* Show details of bank */}
            {(showBankDetails) ? <ShowBankData /> : ""}

          </DialogContentText>
          <Table
            tableHeaderColor="info"
            tableHead={["Cédula", "Nombres", "Grado", "Concepto", "Monto (Bs.)"]}
            tableData={getStudents()  }
          />
          <GridContainer
            direction="row"
            alignItems="center"
          >
            <GridItem xs={8}>
              <Typography color="info" variant="body1" style={{marginTop: "1.4em", marginLeft: "1.4em"}}>
                <strong>Transferencias realizadas</strong>
              </Typography>
            </GridItem>
            <GridItem
              container
              xs={4}
              justify="flex-end"
              alignItems="center"
            >
              {(transfers.length > 0) ? <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                style={{marginTop: "1em"}}
                onClick={() => dispatch({ type: 'CLEAR_TRANSFERS_PARENT' })}
              >
                Limpiar
              </Button> : ""}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                style={{marginTop: "1em", marginLeft: "1em"}}
                onClick={() => openRegisterTranferForm()}
              >
                Agregar
              </Button>
            </GridItem>
          </GridContainer>
          <Table
            tableHeaderColor="info"
            tableHead={["Cédula", "Emisor", "Nro. referencia", "Fecha de emisión", "Banco receptor", "Monto (Bs.)"]}
            tableData={getTransfers()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Regresar
          </Button>
          <Button onClick={() => handleSubmit()} color="primary" disabled={false}>
            Pagar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Model register transfer */}
      <RegisterTransfer open={openRegisterTransfer} banks={banks} />


    </React.Fragment>
  );
}

function RegisterTransfer(props) {

  // Styles
  const classes = useStyles();

  const dispatch = useDispatch();

  // Form fields values
  const [cedula,       setCedula]       = React.useState("");
  const [name,         setName]         = React.useState("");
  const [bank,         setBank]         = React.useState("");
  const [reference,    setReference]    = React.useState("");
  const [mount,        setMount]        = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState("");

  // Form signal validation
  const [messageValidation, setMessageValidation]  = React.useState("");
  const [formInvalid,       setFormInvalid]        = React.useState(false);
  const [cedulaInvalid,     setCedulaInvalid]      = React.useState(false);
  const [nameInvalid,       setNameInvalid]        = React.useState(false);
  const [bankInvalid,       setBankInvalid]        = React.useState(false);
  const [referenceInvalid,  setReferenceInvalid]   = React.useState(false);
  const [mountInvalid,      setMountInvalid]       = React.useState(false);
  const [dateInvalid,       setDateInvalid]        = React.useState(false);

  /**
   * Render options of banks for selector
   *
   * @return {array}
   */
  function getListBanks(banks) {
    return (banks) ? banks.map((bank, index) => {
      return (
        <MenuItem key={index} value={bank.id}>{bank.nombre_corto}</MenuItem>
      );
    }) : [];
  }

  /**
   * Close form action
   *
   */
  function handleClose() {

    // Clear fields in form
    clearFormValues();

    // Create event emit task
    let event = new CustomEvent("closeRegisterTranferForm");

    // Emit event
    window.dispatchEvent(event);
  }

  /**
   * Save transfer input
   *
   */
  function handleSubmit() {

    clearFormValidations();

    if (isFormValid()) {

      // Save transfer
      const transfer = [
        cedula,
        name,
        reference,
        moment(selectedDate).format("YYYY-MM-DD"),
        bank,
        Number.parseFloat(mount).toFixed(2),
      ];

      dispatch({ type: 'ADD_TRANSFERS_PARENT', payload: transfer });

      handleClose();

    }
  }

  /**
   * Clear validations of form
   *
   * @return {void}
   */
  function clearFormValidations() {
    setMessageValidation("");
    setFormInvalid(false);
    setCedulaInvalid(false);
    setNameInvalid(false);
    setBankInvalid(false);
    setReferenceInvalid(false);
    setMountInvalid(false);
    setDateInvalid(false);
  }

  /**
   * Check valid form data
   *
   * @return {boolean}
   */
  function isFormValid() {

    let fieldsEmpty = false;
    let dateIsAfter = false;
    let numberFormatIncorrect = false;

    // Check cedula field
    if (cedula === "") {
      fieldsEmpty = true;
      setCedulaInvalid(true);
    }

    // Check name field
    if (name === "") {
      fieldsEmpty = true;
      setNameInvalid(true);
    }

    // Check bank field
    if (bank === "") {
      fieldsEmpty = true;
      setBankInvalid(true);
    }

    // Check number reference field
    if (reference === "") {
      fieldsEmpty = true;
      setReferenceInvalid(true);
    }

    // Check mount field
    if (mount === 0) {
      fieldsEmpty = true;
      setMountInvalid(true);
    // Check if number is 1305497.87
    } else if (!(/^\d*\.?\d*$/.test(mount.toString()))) {
      numberFormatIncorrect = true;
      setMountInvalid(true);
    }

    // Check date emit field
    if (selectedDate === "") {
      fieldsEmpty = true;
      setDateInvalid(true);
    }

    // Check date emit field
    else if (!moment(selectedDate).isSameOrBefore(moment().now)) {
      dateIsAfter = true;
      setDateInvalid(true);
    }

    // Check form is valid
    const isValid = !(fieldsEmpty || numberFormatIncorrect || dateIsAfter);

    // Set message validation
    setMessageValidation(getMessageOfValidation(fieldsEmpty, numberFormatIncorrect));

    // Show/hide form invalid in view
    setFormInvalid(!isValid);

    return isValid;
  }

  /**
   * Get message of validation for Form
   *
   * @return {string}
   */
  function getMessageOfValidation(fieldsEmpty, numberFormatIncorrect) {

    let message = ""

    if (fieldsEmpty) {
      message = "Por favor ingrese todos los campos.";
    } else if (numberFormatIncorrect) {
      message = "Ingrese el monto en el formato correcto (Ej. 1305457.89).";
    } else {
      message = "La fecha de emisión no puede ser posterior al día actual.";
    }

    return message;
  }

  /**
   * Clear form values
   *
   */
  function clearFormValues() {
    setCedula("");
    setName("");
    setBank("");
    setReference("");
    setMount(0);
    setSelectedDate("")
  }

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{marginRight: "10em"}}
      >
        <DialogTitle id="alert-dialog-title">{"Agregar transferencia"}</DialogTitle>
        <DialogContent>
          {(formInvalid) ?
            <DialogContentText id="alert-dialog-description" style={{color: "red"}}>
              {messageValidation}
            </DialogContentText>
          : ""}

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                id="cardNumber"
                label="Cédula"
                fullWidth
                value={cedula}
                onChange={(event) => setCedula(event.target.value)}
                helperText="Cédula del titular de la cuenta emisora"
                error={cedulaInvalid}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="cardNumber"
                label="Nombres"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
                helperText="Nombres del titular de la cuenta emisora"
                error={nameInvalid}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">{bankSelectorLabel}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={bank}
                  onChange={(event) => setBank(event.target.value)}
                  error={bankInvalid}
                >
                  <MenuItem value="">Seleccione un banco</MenuItem>
                  {getListBanks(props.banks)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="cardNumber"
                label="Nro. referencia"
                fullWidth
                value={reference}
                onChange={(event) => setReference(event.target.value)}
                error={referenceInvalid}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="cvv"
                label="Monto (Bs.)"
                fullWidth
                step="0.01"
                min="0"
                value={mount}
                onChange={(event) => setMount(event.target.value)}
                error={mountInvalid}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="date"
                label="Fecha de emisión"
                type="date"
                value={selectedDate}
                placeholder="dd-mm-yyyy"
                onChange={(event) => setSelectedDate(event.target.value)}
                style={{width: '100%'}}
                InputLabelProps={{
                  shrink: true,
                }}
                error={dateInvalid}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Descartar
          </Button>
          <Button onClick={() => handleSubmit()} color="primary" autoFocus>
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
