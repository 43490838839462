import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// Core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';

import RegisterPay from './RegisterPay';
import AceptTerms  from './AceptTerms';
import SelectConcepts from "./SelectConcepts";

import { useSelector, useDispatch } from 'react-redux';

import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from "@material-ui/core/IconButton";
import PaymentIcon from '@material-ui/icons/Payment';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { getNamesWithFormat } from "../../../utils";
import { NumberFormatSimple } from '../../Utils/NumberFormat';
import { Contract } from "../../../variables/enviroments-files/env";
import {
  getConceptDescription,
  haveConceptsOverdue,
  getConceptsOverdue,
  getStatusForStudent,
  getMountsOfConcepts
} from "../../../models/Student"

const moment   = require('moment');
const axios    = require('axios');
const download = require('downloadjs');

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
      color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
    }
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  chipStatus: {
    backgroundColor: "#dc3545",
    color: "#ffff"
  }
}));

export default function Summary() {

  // Classes for Styles
  const classes = useStyles();

  const dispatch = useDispatch();

  // Repository of Redux consults
  const user              = useSelector(store => store.user.profile);
  const students          = useSelector(store => store.parent.students);
  const payments          = useSelector(store => store.parent.payments);
  const registrationCost  = useSelector(store => store.parent.registrationCost.costo_local);
  const exchangeRateMount = useSelector(store => store.admin.exchangeRate);
  const periodNow         = useSelector(store => store.utils.period);

  // State of component
  const [alert,                  setAlert]                  = useState(false);
  const [registerPay,            setRegisterPay]            = useState(false);
  const [registerTerms,          setRegisterTerms]          = useState(false);
  const [listTransfers,          setListTransfers]          = useState(false);
  const [openPaymentConcepts,    setOpenPaymentConcepts]    = useState(false);
  const [isPaying,               setIsPaying]               = useState(false);
  const [studentsToPay,          setStudentsToPay]          = useState([]);
  const [indexStudentForView,    setIndexStudentForView]    = useState(0);
  const [indexPaymentToDetails,  setIndexPaymentToDetails]  = useState(0);

  // Check exist debt
  const existDebt = (students.filter(student => student.deudaAnterior > 0).length > 0)

  // Check register payment fail
  const subtractRegisterPay = (students.filter(student => student.estadoDeInscripcion !== "1" && student.estadoDeInscripcion !== "3" && student.estadoDeInscripcion !== "4" && !havePaymentInProcess(student.id)).length > 0)

  // Check if not have payment pending
  const havePendingPayments = (payments.filter(payment => payment.estado === 0).length > 0);

  // Check students ok for end process
  const haveStudentsOk = (students.filter(student => student.estadoDeInscripcion === "3").length > 0);

  /**
   * Get header for table students
   *
   */
  function getHeaderTableStudents() {

    let headers = ["Cédula", "Apellidos", "Nombres", "Grado", "Estado", "Concepto", "Monto (Bs.)"];

    // Is paying to register
    if (isPaying)
      headers.push("Pagar a:");
    // Show contract acept register
    else if (haveStudentsOk && !existDebt)
      headers.push("-");

    return headers;
  }

  /**
   * Get students of parent
   *
   */
  function getStudents() {
    return students.map((student, index) => [
      student.cedula,
      getNamesWithFormat(student.apellidos),
      getNamesWithFormat(student.nombres),
      student.gradoletra,
      getStatusStudent(student),
      (havePaymentInProcess(student.id)) ? '---' : getConceptDescription(student, getConceptsOverdue(student.planDeCobro.conceptos), periodNow),
      getMountToPay(student),
      getAditionalRow(student, index)
    ]);
  }

  /**
   * Get status student
   *
   * @param {object} student
   */
  function getStatusStudent(student) {

    if (havePendingPayments || havePaymentInProcess(student.id)) {
      return <><Icon style={{ fontSize: "0.6em", color: "#ffc107" }} fontSize="small">lens</Icon> Pago en proceso</>
    } else if (existDebt) {
      if (student.deudaAnterior > 0)
        return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Deuda pendiente</>
      else
        return <><Icon style={{ fontSize: "0.6em", color: "#2dce89" }} fontSize="small">lens</Icon> Solvente</>
    } else {
      return getStatusForStudent(student);
    }
  }

  /**
   * Get mount to pay for student
   *
   */
  function getMountToPay(student) {

    const confirmRegister = student.estadoDeInscripcion === "4";
    const isRegisteredCancelled = student.estadoDeInscripcion === "3" || confirmRegister;

    // Not show cost with payment pending
    if (havePendingPayments) {
      return 0;
    // Check debt
    } else if (existDebt) {
      return (havePendingPayments) ? 0 : <NumberFormatSimple number={(student.deudaAnterior * exchangeRateMount)} />;
    // Is register cancelled
    } else if (!isRegisteredCancelled) {
      return <NumberFormatSimple number={registrationCost} />;
    // Is register cancelled and have concepts overdue
    } else if (confirmRegister && haveConceptsOverdue(student.planDeCobro.conceptos)) {
      return <NumberFormatSimple number={getMountsOfConcepts(student.planDeCobro.conceptos)} />
    // Is solvent
    } else {
      return 0;
    }

  }

  /**
   * Get information for row aditional of student
   *
   * @param {object} student
   * @param {int}    index
   */
  function getAditionalRow(student, index) {

    if (isPaying) {
      return showSelectorToPayment(student, index);
    } else {
      return showTermsForStudent(student, index);
    }
  }

  /**
   * Check if show selector of payment for student
   *
   * @param student
   * @param index
   */
  function showSelectorToPayment(student, index) {

    const isRegistered = (student.estadoDeInscripcion === "3" || student.estadoDeInscripcion === "4");
    const isSuspend = student.estadoDeInscripcion === "1";

    return (!isRegistered && !isSuspend && !havePaymentInProcess(student.id)) ?
      <>
        <Checkbox
          color="primary"
          onChange={(event) => changeListToPay(event.target.checked, index)}
        />
      </> : "";
  }

  /**
   * Verify if show access ti terms and payments for student
   *
   */
  function showTermsForStudent(student, index) {

    // Check if have concepts pending
    const havePendingConcepts = student.planDeCobro.conceptos.length > 0;

    if (!existDebt && student.estadoDeInscripcion === "3") {
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            style={{marginTop: "1em", marginLeft: "1em"}}
            onClick={() => openRegisterTermsForm(index)}
          >
            Inscribir
          </Button>
        </>
      );
    } else if (!existDebt && student.estadoDeInscripcion === "4") {
      return (
        <>
          <Tooltip title="Contrato">
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => downloadContract(student.id)}
            >
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          {(!havePendingPayments && havePendingConcepts) ?
            <Tooltip title="Pagar">
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={() => setOpenPaymentConcepts(true)}
              >
                <PaymentIcon />
              </IconButton>
            </Tooltip>
            : ""}
        </>
      );
    }
  }

  /**
   * Download contract PDF
   *
   * @param {int} studentId
   */
  function downloadContract(studentId) {

    // Show info sending
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Descargando...' } })

    // Is contract generic
    if (Contract.extern) {
      window.open(Contract.url);
      return;
    }

    // Send request contract
    axios({
      method: 'get',
      url: Contract.url+`?id=${studentId}`,
      withCredentials: true,
      responseType: 'blob',

    })
      .then(function (response) {

        // Download PDF
        const content = response.headers['content-type'];
        download(response.data, "Contrato de servicio educativo 20-21.pdf", content);

        // Hide info sending
        dispatch({ type: 'HIDE_SNACKBAR' })
      })
      .catch(function (error) {
        console.log(error.message);
        // Show alert error
        dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Compruebe su conexión a internet', type: 'warning' } });
      });
  }

  /**
   * Change list of student to pay
   *
   * @param {bool} value
   * @param {int}  index
   */
  function changeListToPay(value, index) {

    // Add candidate to pay
    if (value) {
      setStudentsToPay(studentsToPay => [...studentsToPay, index]);
    // Remove candidate to pay
    } else {
      setStudentsToPay(studentsToPay => studentsToPay.filter(studentIndex => studentIndex !== index));
    }
  }

  /**
   * Get format payments
   *
   */
  function getPayments() {

    // Set format view for payments
    return payments.map((payment, index) => [
      '#' + payment.nro_control.substring(payment.nro_control.length - 6, payment.nro_control.length),
      payment.concepto,
      moment(payment.creado_en).format("DD-MM-YYYY"),
      getStatusPayment(payment.estado),
      <NumberFormatSimple number={payment.monto} />,
      <>
        <Tooltip title="Transferencias">
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => openDetailsPayment(index)}
          >
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      </>
    ]);
  }

  /**
   * Get status for payment
   *
   */
  function getStatusPayment(estado) {
    switch (estado) {
      case 0:
        return <><Icon style={{ fontSize: "0.6em", color: "#ffc107" }} fontSize="small">lens</Icon> En revisión</>;
      case 1:
        return <><Icon style={{ fontSize: "0.6em", color: "#2dce89" }} fontSize="small">lens</Icon> Facturado</>;
      case 2:
        return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Rechazado</>;
      default:
        return <><Icon style={{ fontSize: "0.6em", color: "#ffc107" }} fontSize="small">lens</Icon> En revisión</>;
    }
  }

  /**
   * Open modal register pay
   *
   */
  function openRegisterPayForm() {

    // Check if need pay register
    if (!existDebt && !isPaying) {

      setIsPaying(true);
      setStudentsToPay([]);

      return;
    }

    // Check if selected students to pay
    if (isPaying && studentsToPay.length === 0) {
      // Show alert validation
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Seleccione los alumnos a cancelar la inscripción', type: 'warning' } })

      return;
    }

    // Open modal register pay
    setRegisterPay(true);
  }

  /**
   * Close modal register pay form
   *
   * @return {void}
   */
  function closeRegisterPayForm() {
    // Reset evaluated pay
    setIsPaying(false);
    setStudentsToPay([]);
    setRegisterPay(false)
  }

  /**
   * Open modal register terms
   *
   */
  function openRegisterTermsForm(index) {

    // Set index for student to register
    setIndexStudentForView(index);

    // Open modal register terms
    setRegisterTerms(true);

    // Listen event close modal
    window.addEventListener("closeRegisterTermsForm", () => setRegisterTerms(false));
  }

  /**
   * Open modal details payment
   *
   */
  function openDetailsPayment(index) {

    // Set index for payment to say
    setIndexPaymentToDetails(index);

    // Open modal register terms
    setListTransfers(true);
  }

  /**
   * Close modal details payment
   *
   */
  function closeDetailsPayment() {

    // Close modal register terms
    setListTransfers(false);
  }

  /**
   * Show buttons for register payments
   *
   */
  function showButtonRegisterPayment() {

    // Check if all students hace payments pending
    const allStudentsHavePaymentsPending = (payments.filter(payment => payment.estado === 0).length === students.length);

    // Allow view button payment debt or register
    const allowRegisterPayments = ((existDebt && !havePendingPayments) || (!existDebt && subtractRegisterPay && !allStudentsHavePaymentsPending));

    const textButton = (existDebt) ? "Registrar pago" : "Pagar inscripción";

    if (!isPaying)
      return (allowRegisterPayments) ? <><Button style={{margin: '1.5em'}} variant="outlined" color="primary" onClick={() => openRegisterPayForm()}>{textButton}</Button></> : "";
    else {
      return (
        <>
          <Button variant="outlined" color="primary" onClick={() => setIsPaying(false)}>Descartar</Button>
          <Button style={{margin: '1em'}} variant="outlined" color="primary" onClick={() => openRegisterPayForm()}>Continuar</Button>
        </>
      );
    }
  }

  /**
   * Get students to pay
   *
   */
  function getStudentsToPay() {

    // Pay debt
    if (existDebt)
      return students;
    // Pay register
    else {
      return students.filter((student, index) => {

        let selected = false;

        // Is selected?
        studentsToPay.forEach(studentToPay => {
          if (index === studentToPay)
            selected = true;
        });

        return selected;
      });
    }

  }

  /**
   * Check student is paying register
   *
   */
  function havePaymentInProcess(id) {

    let result = false;

    payments.forEach(payment => {
      if ("alumnos" in payment && payment.estado === 0) {
        payment.alumnos.forEach(aspirant_id => result = aspirant_id === Number.parseInt(id))
      }
    });

    return result;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {(alert) ? <Alert severity="warning" onClose={() => setAlert(false)}>
          <AlertTitle>Advertencia</AlertTitle>
          El alumno tiene una deuda pendiente de un lapso anterior — <strong>¡Por favor verificar antes de inscribir!</strong>
        </Alert> : <></> }
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Representante: {' '} {getNamesWithFormat(user.nombres)} - {user.cedula}</h4>
            <p className={classes.cardCategoryWhite}>
              Estado de inscripción de los alumnos para el período {periodNow.first_year}-{periodNow.second_year}
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {/*<Typography color="info" variant="body2" style={{marginRight: "1.8em"}}>
                {existDebt ? "Periodo 2019-2020" : "Periodo 2020-2021"}
              </Typography>*/}
            </GridContainer>
            {(isPaying) ? <GridContainer
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography color="info" variant="body1">
                Seleccione los alumnos a cancelar el derecho de inscripción
              </Typography>
            </GridContainer> : ""}
            <Table
              tableHeaderColor="info"
              tableHead={getHeaderTableStudents()}
              tableData={getStudents()}
            />
            <GridContainer
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {showButtonRegisterPayment()}
            </GridContainer>
            {(payments.length > 0) ?
            <Table
              tableHeaderColor="info"
              tableHead={["Nro. control", "Concepto", "Fecha de registro", "Estado", "Monto (Bs.)", "-"]}
              tableData={getPayments()}
            /> : ""
            }
          </CardBody>
        </Card>
      </GridItem>

      {/* Form register payment */}
      <RegisterPay
        open={registerPay}
        user={user}
        students={getStudentsToPay()}
        isDebt={existDebt}
        mountToPay={registrationCost}
        onClose={() => closeRegisterPayForm()}
      />

      {/* Form acept terms */}
      <AceptTerms open={registerTerms} user={user} student={students[indexStudentForView]} downloadContract={downloadContract} />

      {/* Dialog list of transfers */}
      <TransfersList open={listTransfers} payment={payments[indexPaymentToDetails]} onClose={closeDetailsPayment} />

      {/* Dialog select concepts of payment */}
      <SelectConcepts open={openPaymentConcepts} user={user} student={students[indexStudentForView]} onClose={() => setOpenPaymentConcepts(false)} />

    </GridContainer>
  );
}

/**
 * Component View list of transfers
 *
 */
function TransfersList(props) {

  const {
    onClose,
    open,
    payment = {
      nro_control: '',
      es_inscripcion: true,
      transferencias: []
    }
  } = props;

  // Get list banks
  const banks = useSelector(state => state.banks.rows);
  const periodNow = useSelector(state => state.utils.period);

  /**
   * Get transfers formated
   *
   * @return {array}
   */
  function getTransfers() {

    return payment.transferencias.map(transfer => [
      transfer.cedula,
      transfer.nombres,
      '#' + transfer.referencia,
      moment(transfer.fecha_emision).format('DD-MM-YYYY'),
      getNameBank(transfer.banco_id),
      (transfer.comentario) ? transfer.comentario : 'Sin observación',
      getStatusPayment(transfer),
      <NumberFormatSimple number={transfer.monto} />
    ]);
  }

  /**
   * Get name of bank
   *
   * @param  {int}   id
   * @return {string}
   */
  function getNameBank(id) {

    const result = banks.filter(bank => Number.parseInt(bank.id) === id);

    return (result.length > 0) ? result[0].nombre_corto : "-";
  }

  /**
   * Get status of payment
   */
  function getStatusPayment(transfer) {
    switch (transfer.estado_transferencias_id) {
      case 1:
        return <><Icon style={{ fontSize: "0.6em", color: "#2dce89" }} fontSize="small">lens</Icon> Aprobada</>;
      case 2:
        return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Rechazada</>;
      default:
        return <><Icon style={{ fontSize: "0.6em", color: "#ffc107" }} fontSize="small">lens</Icon> En revision</>;
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        onClose={onClose}
        open={open}
      >
        <DialogTitle>Transferencias adjuntadas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Concepto: </strong> {payment.concepto} <br></br>
            <strong>Nro de control: </strong> {'#' + payment.nro_control.substring(payment.nro_control.length - 6, payment.nro_control.length)}
          </DialogContentText>
          <Table
            tableHeaderColor="info"
            tableHead={["Cédula", "Emisor", "Referencia", "Emisión", "Banco receptor", "Observaciones", "Estado", "Monto"]}
            tableData={getTransfers()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
