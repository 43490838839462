import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";

import { useSelector } from "react-redux";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function Users() {

  const classes = useStyles();

  const users = useSelector(state => state.user.rows.filter(user => user.cedula !== 'V-27077383'));

  /**
   * Get users formated
   *
   */
  function getUsers() {
    return users.map(user => [
      user.cedula,
      user.nombres,
      getRoleUser(user.rol),
      getStatusUser(user.activo)
    ]);
  }

  /**
   * Filter role of user
   *
   */
  function getRoleUser(rol) {
    switch (rol) {
      case "1":
        return "Administrador";
      case "4":
        return "Representante";
      default:
        return "Administrador";
    }
  }

  /**
   * Filter status of user
   *
   */
  function getStatusUser(status) {
    return (status) ? <><Icon style={{ fontSize: "0.6em", color: "#2dce89" }} fontSize="small">lens</Icon> Activo</> : <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Bloqueado</>;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Administradores</h4>
            <p className={classes.cardCategoryWhite}>
              Listado de administradores del sistema
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={["Cédula", "Nombres", "Rol", "Acceso"]}
              tableData={getUsers()}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
