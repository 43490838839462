import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { URL, nameShortCollege } from "../../variables/enviroments-files/env";

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from "react-router-dom";

// import { saveEmail } from '../../redux/actions/user';

import logo from "assets/img/change-password.png";

const axios = require('axios');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sisconot.com.ve/">
        Sisconot
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: theme.spacing(1),
    width: "69px",
    top: "52px",
    verticalAlign: "middle",
    border: "0"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EmailVerification() {

  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  // Fields values
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  // Form view validations
  const [formInvalid, setFormInvalid] = useState(false);
  const [messageValidation, setMessageValidation] = useState("");

  // User logged in
  const user = useSelector(store => store.user.profile);

  /**
   * Event click button send password
   *
   * @return {void}
   */
  function handleClick() {

    // Hide form invalid
    hideFormInvalid();

    // Validate fields empty
    if (password !== '' && passwordConfirmation !== '') {

      if (password !== passwordConfirmation) {
        showFormInvalid("Los contraseñas no coinciden.");
        return;
      }

      // Send request save password
      axios({
        method: 'post',
        url: URL+`/changePasswordDefault?id=${user.id}&nuevaClave=${password}`,
        withCredentials: true
      })
        .then(function (response) {
          // Fail register
          if (!response.data) {
            showFormInvalid("Hubo un error, intente nuevamente.");
          } else {

            // Send view dashboard parent
            history.push("/parent");
          }

        })
        .catch(function (error) {
          console.log(error.message);
          showFormInvalid("Verifique su conexión a internet");
        });

    } else
      showFormInvalid("Por favor rellena ambos campos");

  }

  function showFormInvalid(message) {
    setFormInvalid(true);
    setMessageValidation(message);
  }
  function hideFormInvalid() {
    setFormInvalid(false);
    setMessageValidation("");
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/*<Avatar className={classes.avatar} src={logo} />*/}
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography variant="body1">
          Ingresa una nueva clave de acceso
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          type="password"
          id="password"
          label="Nueva contraseña"
          name="new-password"
          autoComplete="ÑÖcompletes"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={formInvalid}
          helperText={messageValidation}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="confirm-password"
          label="Vuelve a ingresar la contraseña"
          type="password"
          id="new-password"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          error={formInvalid}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => handleClick()}
        >
          Enviar
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}