import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Login                  from "../views/Auth/Login";
import LoginInside            from "../views/Auth/LoginInside";
import EmailVerification      from "../views/Auth/EmailVerification";
import VerificationCode       from "../views/Auth/VerificationCode";
import CompletedVerification  from "../views/Auth/CompletedVerification";
import ChangePassword         from "../views/Auth/ChangePassword";

// Redux store
import { Provider } from "react-redux";
import store from '../redux';

export default function Auth() {
  return(
    <Provider store={store}>
      <Switch>
        <Route path="/auth/login"                 component={Login} />
        <Route path="/auth/loginInside"           component={LoginInside} />
        <Route path="/auth/registerEmail"         component={EmailVerification} />
        <Route path="/auth/verificationCode"      component={VerificationCode} />
        <Route path="/auth/completed"             component={CompletedVerification} />
        <Route path="/auth/changePasswordDefault" component={ChangePassword} />
        <Redirect from="/auth" to="/auth/login" />
      </Switch>
    </Provider>
  );
}