import { combineReducers } from 'redux';
import user     from './user';
import admin    from './admin';
import parent   from './parent';
import banks    from './banks';
import transfer from './transfer';
import utils    from './utils';

export default combineReducers({
  user,
  admin,
  parent,
  banks,
  transfer,
  utils
});