/**
 * Number version code
 *
 * @var {string} version
 */
export const version = "1.2.3";

/**
 * URL access API
 *
 * @var {string} URL
 */
// export const URL = "http://localhost:3000";
// export const URL_API_GRAPHQL_V2 = "http://localhost:3000/graphql";
export const URL = "https://api.colegiosigloxxi.net";
export const URL_API_GRAPHQL_V2 = "https://api.colegiosigloxxi.net/graphql";

/**
 * Contract File
 *
 * @var {string} URL
 */
export const Contract = {
  url: "https://sisconot.nyc3.digitaloceanspaces.com/reports/Planilla_de_Inscripcion.doc",
  extern: true
};

/**
 * Name long college
 *
 * @var {string} nameLongCollege
 */
export const nameLongCollege = "Unidad Educativa Colegio Siglo XXI";

/**
 * Name short college
 *
 * @var {string} nameShortCollege
 */
export const nameShortCollege = "Siglo XXI";

/**
 * Custom styles of logo principal
 *
 * @var {string} Logo
 */
export const Logo = {
  "AdminView": {
    "width": "5.2em",
    "height": "1.3em"
  },
  "LoginView": {
    "width": "106px"
  },
  "SummaryView": {
    "width": "100px",
    "top": "22px",
    "height": "1.3em",
    "marginLeft": "29%"
  }
};

/**
 * Custom message for aspirant inactive
 *
 * @var {string} inactiveMessage
 */
export const inactiveMessage = null;

/**
 * Custom label for input select a back
 *
 * @var {string} bankSelectorLabel
 */
export const bankSelectorLabel = "Banco emisor";

/**
 * Show Bank data in register payment
 *
 * @var {boolean} showBankDetails
 */
export const showBankDetails = true;

/**
 * Show name College in Admin Layout
 *
 * @var {boolean} showCollegeInAdmin
 */
export const showCollegeInAdmin = false;
