import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { URL, nameShortCollege } from "../../variables/enviroments-files/env";

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from "react-router-dom";

import { saveEmail } from '../../redux/actions/user';

import logo from "assets/img/email-confirmation.png";

const axios = require('axios');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sisconot.com.ve/">
        Sisconot
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: theme.spacing(1),
    width: "69px",
    top: "52px",
    verticalAlign: "middle",
    border: "0"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EmailVerification() {

  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  // Fields values
  const [email, setEmail] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");

  // Form view validations
  const [formInvalid, setFormInvalid] = useState(false);
  const [messageValidation, setMessageValidation] = useState("");

  // User logged in
  const user = useSelector(store => store.user.profile);

  /**
   * Event click button sign in
   *
   * @return {void}
   */
  function handleClick() {

    // Hide form invalid
    hideFormInvalid();

    // Validate fields empty
    if (email !== '' && emailConfirmation !== '') {

      if (email !== emailConfirmation) {
        showFormInvalid("Los correos no coinciden.");
        return;
      }

      // Send request save email
      axios({
        method: 'post',
        url: URL+`/registerEmail?id=${user.id}&email=${email}`,
        withCredentials: true
      })
        .then(function (response) {

          // Fail register
          if (!response.data.created) {
            showFormInvalid(response.data.message);
          } else {

            // Save email of user in storage
            dispatch(saveEmail(email));

            // Send view code verification to email
            history.push("/auth/verificationCode");
          }

        })
        .catch(function (error) {
          console.log(error.message);
          showFormInvalid("Verifique su conexión a internet");
        });

    } else
      showFormInvalid("Por favor rellena ambos campos");

  }

  function showFormInvalid(message) {
    setFormInvalid(true);
    setMessageValidation(message);
  }
  function hideFormInvalid() {
    setFormInvalid(false);
    setMessageValidation("");
  }

  function defaultPassword() {
    
    hideFormInvalid();
    
    axios({
        method: 'post',
        url: URL+`/setDefaultEmail?cedula=${user.cedula}`,
        withCredentials: true
      })
      .then(function (response) {
        // Send view email verification completed
        history.push("/auth/completed");
      })
      .catch(function (error) {
        console.log(error.message);
        showFormInvalid("Verifique su conexión a internet");
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/*<Avatar className={classes.avatar} src={logo} />*/}
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography variant="body1">
          Ingresa un correo para recibir notificaciones
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email-verification"
            autoComplete="ÑÖcompletes"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={formInvalid}
            helperText={messageValidation}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="confirm-email"
            label="Vuelve a ingresar el correo"
            type="text"
            id="password"
            value={emailConfirmation}
            onChange={(e) => setEmailConfirmation(e.target.value)}
            error={formInvalid}
          />
          <Typography variant="body2">
            <Link style={{ cursor: "pointer" }} onClick={(e) => defaultPassword()}>Omitir este paso</Link>
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleClick()}
          >
            Enviar
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}