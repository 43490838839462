import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%"
  }
}));

export default function PaymentForm() {

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <React.Fragment>
      {/*<Typography variant="h6" gutterBottom>
        Payment method
      </Typography>*/}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Banco emisor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            >
              <MenuItem value="">Seleccione un banco</MenuItem>
              <MenuItem value="0156">100%BANCO</MenuItem>
              <MenuItem value="0196">ABN AMRO BANK</MenuItem>
              <MenuItem value="0172">BANCAMIGA BANCO MICROFINANCIERO, C.A.</MenuItem>
              <MenuItem value="0171">BANCO ACTIVO BANCO COMERCIAL, C.A.</MenuItem>
              <MenuItem value="0166">BANCO AGRICOLA</MenuItem>
              <MenuItem value="0175">BANCO BICENTENARIO</MenuItem>
              <MenuItem value="0128">BANCO CARONI, C.A. BANCO UNIVERSAL</MenuItem>
              <MenuItem value="0164">BANCO DE DESARROLLO DEL MICROEMPRESARIO</MenuItem>
              <MenuItem value="0102">BANCO DE VENEZUELA S.A.I.C.A.</MenuItem>
              <MenuItem value="0114">BANCO DEL CARIBE C.A.</MenuItem>
              <MenuItem value="0149">BANCO DEL PUEBLO SOBERANO C.A.</MenuItem>
              <MenuItem value="0163">BANCO DEL TESORO</MenuItem>
              <MenuItem value="0176">BANCO ESPIRITO SANTO, S.A.</MenuItem>
              <MenuItem value="0115">BANCO EXTERIOR C.A.</MenuItem>
              <MenuItem value="0003">BANCO INDUSTRIAL DE VENEZUELA.</MenuItem>
              <MenuItem value="0173">BANCO INTERNACIONAL DE DESARROLLO, C.A.</MenuItem>
              <MenuItem value="0105">BANCO MERCANTIL C.A.</MenuItem>
              <MenuItem value="0191">BANCO NACIONAL DE CREDITO</MenuItem>
              <MenuItem value="0116">BANCO OCCIDENTAL DE DESCUENTO.</MenuItem>
              <MenuItem value="0138">BANCO PLAZA</MenuItem>
              <MenuItem value="0108">BANCO PROVINCIAL BBVA</MenuItem>
              <MenuItem value="0104">BANCO VENEZOLANO DE CREDITO S.A.</MenuItem>
              <MenuItem value="0168">BANCRECER S.A. BANCO DE DESARROLLO</MenuItem>
              <MenuItem value="0134">BANESCO BANCO UNIVERSAL</MenuItem>
              <MenuItem value="0177">BANFANB</MenuItem>
              <MenuItem value="0146">BANGENTE</MenuItem>
              <MenuItem value="0174">BANPLUS BANCO COMERCIAL C.A</MenuItem>
              <MenuItem value="0190">CITIBANK.</MenuItem>
              <MenuItem value="0121">CORP BANCA.</MenuItem>
              <MenuItem value="0157">DELSUR BANCO UNIVERSAL</MenuItem>
              <MenuItem value="0151">FONDO COMUN</MenuItem>
              <MenuItem value="0601">INSTITUTO MUNICIPAL DE CR&#201;DITO POPULAR</MenuItem>
              <MenuItem value="0169">MIBANCO BANCO DE DESARROLLO, C.A.</MenuItem>
              <MenuItem value="0137">SOFITASA</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="cardNumber"
            label="Nro. referencia"
            fullWidth
            autoComplete="cc-number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="cvv"
            label="Monto"
            // helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{marginTop: "0"}}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Fecha de emisión"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {/*<Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid>*/}
      </Grid>
    </React.Fragment>
  );
}