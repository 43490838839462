export const FETCHED_USER_PROFILE = "FETCHED_USER_PROFILE";

export const FETCHED_USERS_LIST = "FETCHED_USERS_LIST";

export const ADD_USER_PROFILE = "ADD_USER_PROFILE";

export const ADD_EMAIL_USER_PROFILE = "ADD_EMAIL_USER_PROFILE";

export const ADD_EMAIL_VERIFIED_USER_PROFILE = "ADD_EMAIL_VERIFIED_USER_PROFILE";

export const LOGOUT_USER_PROFILE = "LOGOUT_USER_PROFILE";

export const CHANGE_ACCESS_USER_PROFILE = "CHANGE_ACCESS_USER_PROFILE";