import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

import { useSelector, useDispatch } from 'react-redux';

export default function Utils() {

  const dispatch = useDispatch();

  const active   = useSelector(state => state.utils.snackbar.active);
  const message  = useSelector(state => state.utils.snackbar.message);
  const position = useSelector(state => state.utils.snackbar.position);
  const type     = useSelector(state => state.utils.snackbar.type);

  /**
   * Close snackbat
   *
   * @param event
   * @param reason
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: 'HIDE_SNACKBAR' });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={position}
        open={active}
        message={message}
        autoHideDuration={4000}
        onClose={handleClose}
        action={
          <React.Fragment>
            {/*<Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>*/}
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}