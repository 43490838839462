import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useSelector, useDispatch } from "react-redux";
import { getTransfers, changeStatusTransfer } from "../../../redux/actions/transfer";
import { saveNewExchangeRate } from "../../../redux/actions/admin";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

const moment = require('moment');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function Payments() {

  const classes = useStyles();

  const dispatch = useDispatch();

  const payments          = useSelector(state => state.transfer.rows);
  const banks             = useSelector(state => state.banks.rows);
  const exchangeRateMount = useSelector(store => store.admin.exchangeRate);

  const [dateStartSearch, setDateStartSearch] = useState(moment().format("YYYY-MM-DD"));
  const [dateEndSearch,   setDateEndSearch]   = useState(moment().format("YYYY-MM-DD"));

  const [exchangeRateForm, setExchangeRateForm]     = useState(false);
  const [editComment, setEditComment]               = useState(false);
  const [transferToChangeID, setTransferToChangeID] = useState(0);
  const [currentPage, setCurrentPage]               = useState(1);
  const nroPaginates = useSelector(
    (state) => state.transfer.nro_paginas
  );

  /**
   * Update list transfer for date
   *
   */
  useEffect(() => searchTransfers(), [dateStartSearch, dateEndSearch]);

  // Search registers paginated
  useEffect(() => searchTransfers(), [currentPage]);

  /**
   * Get transfer list formated
   *
   */
  function getPayments() {
    return payments.map((transfer, index) => [
        transfer.cedula,
        transfer.nombres,
        "#" + transfer.referencia,
        moment(transfer.fecha_emision).format("DD-MM-YYYY"),
        getNameBank(transfer),
        getStatusPayment(transfer),
        transfer.monto.toFixed(2),
        <>
          <Tooltip title="Verificar">
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => dispatch(changeStatusTransfer(transfer.id, 1, [dateStartSearch, dateEndSearch], currentPage))}
            >
              <DoneIcon style={{color: "#2dce89"}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Rechazar">
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => openEditCommentTransfer(transfer.id)}
            >
              <NotInterestedIcon style={{color: "#dc3545"}} />
            </IconButton>
          </Tooltip>
        </>
    ]);
  }

  /**
   * Get name Bank
   */
  function getNameBank(transfer) {

    const bankFind = banks.filter(bank => bank.id === transfer.banco_id);

    return (bankFind.length > 0) ? bankFind[0].nombre_corto : "No encontrado";
  }

  /**
   * Get status of payment
   */
  function getStatusPayment(transfer) {
    switch (transfer.estado_transferencias_id) {
      case 1:
        return <><Icon style={{ fontSize: "0.6em", color: "#2dce89" }} fontSize="small">lens</Icon> Verificada</>;
      case 2:
        return <><Icon style={{ fontSize: "0.6em", color: "#dc3545" }} fontSize="small">lens</Icon> Revocada</>;
      default:
        return <><Icon style={{ fontSize: "0.6em", color: "#ffc107" }} fontSize="small">lens</Icon> En espera</>;
    }
  }

  /**
   * Open form for modify exchange rate
   *
   */
  function openExchangeRateForm() {

    // Set flag open form modify rate
    setExchangeRateForm(true);

    // Listen event close form
    window.addEventListener("closeExchangeRateForm", () => setExchangeRateForm(false));
  }

  /**
   * Open form for modify commment for transfer
   *
   */
  function openEditCommentTransfer(id) {

    // Set ID of transfer to modify
    setTransferToChangeID(id);

    // Set flag open form comment transfer
    setEditComment(true);
  }

  /**
   * Close form for modify commment for transfer
   *
   */
  function closeEditCommentTransfer() {

    // Set flag close form comment transfer
    setEditComment(false);
  }

  /**
   * Search transfers
   *
   */
  function searchTransfers() {

    // Send request get new list transfers
    dispatch(getTransfers(dateStartSearch, dateEndSearch, currentPage));

    // Show info of searching
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Buscando...' } });
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Cancelaciones</h4>
              <p className={classes.cardCategoryWhite}>
                Listado de transferencias
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <form className={classes.container} noValidate>
                    <TextField
                      id="date"
                      label="Desde"
                      type="date"
                      defaultValue={moment().format("YYYY-MM-DD")}
                      value={dateStartSearch}
                      onChange={(event) => {
                        setCurrentPage(1);
                        setDateStartSearch(event.target.value);
                      }}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{marginRight: "10px"}}
                    />
                    <TextField
                      id="date"
                      label="Hasta"
                      type="date"
                      defaultValue={moment().format("YYYY-MM-DD")}
                      value={dateEndSearch}
                      onChange={(event) => {
                        setCurrentPage(1);
                        setDateEndSearch(event.target.value);
                      }}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} container direction="row" justify="flex-end" alignItems="center">
                  <Typography variant="body1" gutterBottom style={{marginTop: "0.6em"}}>
                    <strong>Tasa de cambio: </strong> {exchangeRateMount} Bs.
                  </Typography>
                  <Tooltip title="Cambiar">
                    <IconButton color="primary" aria-label="add to shopping cart" onClick={() => openExchangeRateForm()}>
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
              <Table
                tableHeaderColor="info"
                tableHead={["Cédula", "Beneficiario", "Referencia", "Fecha emisión", "Banco receptor", "Estado", "Monto (Bs.)", "Acción"]}
                tableData={getPayments()}
              />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Pagination
                  count={nroPaginates}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  style={{ marginTop: "1em" }}
                />
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ModifyExchangeRate open={exchangeRateForm} />

      <EditCommentTransfer
        open={editComment}
        onClose={closeEditCommentTransfer}
        transferId={transferToChangeID}
        dateStartSearch={dateStartSearch} dateEndSearch={dateEndSearch}
        currentPage={currentPage}
      />
    </>
  );
}

/**
 * Component edit exchange rate
 *
 */
function ModifyExchangeRate(props) {

  const dispatch = useDispatch();

  const user = useSelector(state => state.user.profile)

  const [mount, setMount] = React.useState(0);

  const handleSubmit = () => {

    // Validate field
    if (mount === 0) {
      // Show alert of validation
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Ingrese el total de la nueva tasa', type: 'warning' } })

      return;
    }

    const ExchangeRateInput = {
      monto:  mount,
      userId: user.id
    }

    dispatch(saveNewExchangeRate(ExchangeRateInput));

    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Enviando...' } });

    handleClose();
  }

  const handleClose = () => {

    // Create event emit task
    let event = new CustomEvent("closeExchangeRateForm");

    // Emit event
    window.dispatchEvent(event);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modificar tasa de cambio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Recuerde que todos los montos totales del derecho a inscripción estaran sujetos en relación a esta tasa.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tasa de cambio"
            type="number"
            min="0"
            value={mount}
            onChange={(event) => setMount(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/**
 * Component edit comment transfer
 *
 */
function EditCommentTransfer(props) {

  const { onClose, transferId = 0, dateStartSearch = "", dateEndSearch = "", currentPage = 1 } = props;

  const dispatch = useDispatch();

  const [comment, setComment] = React.useState("");

  const lengthComment = comment.length;

  const handleSubmit = () => {

    // Validate field
    if (comment === "") {
      // Show alert of validation
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Ingrese la observación', type: 'warning' } })

      return;
    }

    if (comment.length > 80) {
      // Show alert of validation
      dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'El límite del comentario es de 80 caracteres', type: 'warning' } })

      return;
    }

    dispatch(changeStatusTransfer(transferId, 2, [dateStartSearch, dateEndSearch], currentPage, comment))

    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Enviando...' } });

    handleClose();
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Rechazar transferencia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese la observación de la invalidez en la transferencia.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Observación"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            fullWidth
            helperText={lengthComment + "/80"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}