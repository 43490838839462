import {
  FETCHED_BANKS_LIST
} from '../../actions/banks/types';

const initialState = {
  rows:  []
};

export default function (state = initialState, action) {

  let result;

  switch (action.type) {
    case FETCHED_BANKS_LIST:
      result = {
        ...state,
        rows: action.payload
      };
      break;
    default:
      result = state;
      break;
  }

  return result;
}