import React, { useState } from 'react';

export default function ShowBankData() {

  // Show Bank Data in pay Modal
  const [showBankData, setShowBankData] = useState(false);

  return(
    <>
      <strong>Datos bancarios</strong>
      <br/>
      <b> Banco: </b>Banco nacional de crédito (BNC)
      <br/>
      <b> Número de cuenta: </b>0191-0191-69-1000003697
      <br/>
      <b> Nombre: </b>Segundo Gonzalez
      <br/>
      <b> Cédula: </b>V-4034434
      <br/>
      <b> Celular (Pago móvil): </b>0414-8721208
    </>
  );
}

// <>
//   <a onClick={() => setShowBankData(!showBankData)} style={{cursor: 'pointer'}}>
//     { (!showBankData) ? 'Ver datos bancarios' : 'Ocultar datos bancario'} </a>
//   {(showBankData) ?
//     <>
//       <br/>
//       <b> Banco: </b>Banco nacional de crédito (BNC)
//       <br/>
//       <b> Número de cuenta: </b>0191-0191-69-1000003697
//       <br/>
//       <b> Nombre: </b>Segundo Gonzalez
//       <br/>
//       <b> Cédula: </b>V-4034434
//       <br/>
//       <b> Celular (Pago móvil): </b>0414-8721208
//     </>
//     : "" }
// </>