/**
 * Get name with format
 * GONZALEZ RUJANO, LEONARDO JOSE -> Leonardo Gonzalez
 *
 * @param  {string} fullname
 * @return {string}
 */
export function getNameFormat(fullname= "") {

  if (fullname === "")
    return "";

  const separator = (fullname.search(",") === -1) ? " " : ",";

  let lastnames = fullname.split(separator)[0];
  let names     = fullname.split(separator)[1];

  let lastname = lastnames.split(" ")[0];
  let name     = names.search(" ") ? names.split(" ")[1] : names;

  lastname = lastname.substring(0, 1) + lastname.substring(1).toLowerCase();
  name     = name.substring(0, 1) + name.substring(1).toLowerCase();

  return name + " " + lastname;
}

/**
 * Transfor some text to format
 * LEONARDO JOSE -> Leonardo Gonzalez
 *
 * @param  {string} fullname
 * @return {string}
 */
export function getNamesWithFormat(fullname= "") {

  const names = fullname.split(" ");
  let newNames = "";

  names.forEach(name => newNames = newNames + name.substring(0, 1) + name.substring(1).toLowerCase() + " ");

  return newNames;
}

/**
 * Get name month for 0 = "Enero"
 *
 * @param  {int} numberMonth
 * @return {string}
 */
export function getNameMonth(numberMonth) {
  switch (numberMonth) {
    case 0:
      return "Enero";
    case 1:
      return "Febrero";
    case 2:
      return "Marzo";
    case 3:
      return "Abril";
    case 4:
      return "Mayo";
    case 5:
      return "Junio";
    case 6:
      return "Julio";
    case 7:
      return "Agosto";
    case 8:
      return "Septiembre";
    case 9:
      return "Octubre";
    case 10:
      return "Noviembre";
    case 11:
      return "Diciembre";
    default:
      return "Enero";
  }
}

/**
 * Do text compatible for query GraphQL
 *
 * @param  {string} text
 * @return {string}
 */
export function doTextCompatibleWithQuery(text) {

  // Replace \n in text for Query
  text = text.replace(/\n/g, " ");

  // Replace " in text for Query
  text = text.replace(/"/g, "");

  return text;
}

/**
 * Get initial letter for weekday
 *
 * @param  {int}    numberDay
 * @return {string}
 */
export function getInitialDay(numberDay) {
  switch (numberDay) {
    case 0:
      return "D";
    case 1:
      return "L";
    case 2:
      return "M";
    case 3:
      return "M";
    case 4:
      return "J";
    case 5:
      return "V";
    case 6:
      return "S";
    default:
      return "D";
  }
}