import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { URL, nameShortCollege, version, Logo } from "../../variables/enviroments-files/env";

import { useDispatch } from 'react-redux'

import { useHistory } from "react-router-dom";

import { saveProfile } from '../../redux/actions/user';
import { getDataParent } from '../../redux/actions/parent';
import { getDataAdmin } from '../../redux/actions/admin';
import { getPeriod } from '../../redux/actions/utils';

import logo from "assets/img/logos/reactlogo.png";

const axios = require('axios');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Version ${version} - Copyright © `}
      <Link color="inherit" href="https://sisconot.com.ve/">
        Sisconot
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: theme.spacing(1),
    width: Logo.hasOwnProperty('LoginView') ? Logo.LoginView.width : "55px",
    top: "22px",
    verticalAlign: "middle",
    border: "0"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {

  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  // Fields values
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Form view validations
  const [formInvalid, setFormInvalid] = useState(false);
  const [messageValidation, setMessageValidation] = useState("");

  function getDataInitialOfRole(user) {
    if (user.rol_id === 1)
      dispatch(getDataAdmin());
    if (user.rol_id === 4)
      dispatch(getDataParent(user.cedula));
  }

  /**
   * Event click button sign in
   *
   * @return {void}
   */
  async function handleClick() {

    // Close session after
    await axios({
      method: 'get',
      url: URL+'/logout',
      withCredentials: true
    })

    // Hide form invalid
    hideFormInvalid();

    // Validate fields empty
    if (username !== '' && password !== '') {

      // Send request authentication
      axios({
        method: 'post',
        url: URL+'/login?username='+username+'&password='+password,
        withCredentials: true
      })
        .then(function (response) {
          // Fail authentication
          if (!response.data) {
            showFormInvalid("Credenciales invalidas");
          } else {

            // Get period data
            dispatch(getPeriod());

            // Get data user logged in
            const user = response.data;

            // Save data user
            dispatch(saveProfile(user));

            // Request data initial for user
            getDataInitialOfRole(user);

            // Send to verification if not have email
            if (user.rol_id === 4 && !user.correo)
              history.push("/auth/registerEmail");

            // Send to verification if not is verified
            else if (user.rol_id === 4 && user.correo && !user.correoVerificado)
              history.push("/auth/verificationCode");

            // Send dashboard of role admin
            else if (user.rol_id === 1) {
              history.push("/admin");
            }

            // Send dashboard of role parent
            else if (user.rol_id === 4) {
              history.push("/parent");
            }

          }

        })
        .catch(function (error) {
          console.log(error.message);
          showFormInvalid("Verifique su conexión a internet");
        });
    } else
      showFormInvalid("Ingrese su cédula y contraseña");

  }

  function showFormInvalid(message) {
    setFormInvalid(true);
    setMessageValidation(message);
  }

  function hideFormInvalid() {
    setFormInvalid(false);
    setMessageValidation("");
  }

  function resetPassword() {

    // Validate username field
    if (username === '') {
      showFormInvalid("Ingrese su cédula");
      return;
    }

    // Show alert sending
    dispatch({ type: 'SHOW_SNACKBAR', payload: { message: 'Enviando...', type: 'info' } });

    // Send request reset password
    axios({
      method: 'post',
      url: URL+`/resetPassword?cedula=${username}`,
      withCredentials: true
    }).then(function (response) {

      response = response.data;

      dispatch({ type: 'HIDE_SNACKBAR', payload: { message: 'Enviando...', type: 'info' } });

      if (!response.reset)
        showFormInvalid(response.message);
      else {
        // Save data user
        dispatch(saveProfile(response.user));
        // Request data initial for user
        getDataInitialOfRole(response.user);

        // Email is default or not have email
        if (response.user.correo == 'default@default.com' || !response.user.correo) {
          history.push("/auth/registerEmail");
          return;
        }
        // Send view recover password
        history.push("/auth/verificationCode", { allowChangeEmail: { allow: false } });
      }
    });

  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/*<Avatar className={classes.avatar} src={logo} />*/}
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography component="h1" variant="h5">
          Colegio { nameShortCollege }
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Cédula"
            name="email"
            autoComplete="current-cedula"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={formInvalid}
            helperText={messageValidation}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Clave"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={formInvalid}
          />
          <Typography variant="body2">
            <Link href="#" onClick={() => resetPassword()}>¿Olvidaste tu contraseña?</Link>
          </Typography>
          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recuerdame"
          />*/}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleClick()}
          >
            Ingresar
          </Button>
          {/*<Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>*/}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
