import {
  FETCH_PERIOD
} from './types';
import { URL } from '../../../variables/enviroments-files/env'; 
const axios = require('axios');

export function getPeriod() {
  return dispatch => {
    axios({
      method: 'get',
      url: URL + '/period',
      withCredentials: true
    }).then(response => {
      dispatch({ type: FETCH_PERIOD, payload: response.data });
    })
  }
}