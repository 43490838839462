import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

const products = [
  { name: 'Recaudo nro. 1', desc: 'Descripción del documento',   price: false },
  { name: 'Recaudo nro. 2', desc: 'Descripción del documento',   price: false },
  { name: 'Recaudo nro. 3', desc: 'Descripción del documento',   price: false }
];
const addresses = ['1 Material-UI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
const payments = [
  { name: 'Card type', detail: 'Visa' },
  { name: 'Card holder', detail: 'Mr John Smith' },
  { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
  { name: 'Expiry date', detail: '04/2024' },
];

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review() {

  const classes = useStyles();

  const [list, setList] = useState([false, false, false]);
  const [counter, setCounter] = useState(0);

  function getDocument(index) {

    let newList = list;

    newList[index] = true;

    let newCounter = counter + 1;

    setList(newList);
    setCounter(newCounter);

    window.open("https://sisconot.nyc3.digitaloceanspaces.com/collections/PlanillaRecaudos.pdf");

    if (newCounter === 3) {

      // Create event emit progress percent
      let event = new CustomEvent("collectionCompleted", { detail: { completed: true } });

      // Emit event
      window.dispatchEvent(event);
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Recaudos solicitados
      </Typography>
      <List disablePadding>
        {products.map((product, index) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            {(!list[index]) ? <Button color="primary" startIcon={<Icon>save_alt</Icon>} onClick={() => getDocument(index)}>Obtener</Button> : <Icon style={{color: "#30C926"}}>done</Icon>}
            {/*<Icon style={{color: "#30C926"}}>done</Icon> {}*/}
            {/*<Icon style={{marginRight: "0.1em"}}>save_alt</Icon> Obtener*/}
            {/*<Typography variant="body2">{product.price}</Typography>*/}
          </ListItem>
        ))}
        {/*<ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            $34.06
          </Typography>
        </ListItem>*/}
      </List>
      {/*<Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Shipping
          </Typography>
          <Typography gutterBottom>John Smith</Typography>
          <Typography gutterBottom>{addresses.join(', ')}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>*/}
    </React.Fragment>
  );
}